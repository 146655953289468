/**
 * @summary Companies service
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Filter} from '@shared/models/filter.model';
import {HttpClient} from '@angular/common/http';

// Services
import {ApiService} from '@shared/services/api.service';

// Interface and models
import {SearchData} from '@shared/models/search-data.model';
import {Similars} from '@shared/common-interfaces/similars-interface';
import {HeatMap} from '@shared/common-interfaces/heat-map-interface';
import {Company} from '@shared/common-interfaces/company-interface';
import {SearchList} from '@shared/common-interfaces/search-list-interface';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {Entities} from '@shared/common-interfaces/entities-interface';
import {Radar} from '@shared/common-interfaces/radar-interface';
import {Region} from '@shared/common-interfaces/region-interface';
import {Evaluations} from '@shared/common-interfaces/evaluations-interface';
import {NewsList} from '@shared/common-interfaces/news-list-interface';

@Injectable()
export class CompaniesService {
  filtersSubject = new Subject<Filter>();
  searchSubject = new BehaviorSubject<SearchList>(null);
  companiesSubject = new BehaviorSubject<Company[]>(null);
  companySubject = new BehaviorSubject<Company>(null);
  similarSubject = new BehaviorSubject<Company[]>(null);

  /**
   * @summary CompaniesSearch component constructor
   * @param apiService - Api service
   * @param http - HttpClient service
   */
  constructor(private apiService: ApiService,
              private http: HttpClient) {
  }

  /**
   * @summary Get company list from server
   */
  getRadars(): Observable<Radar []> {
    return this.apiService.get(`api/v1/radars/`);
  }

  /**
   * @summary Get company list from server
   * @param filter - filter data
   */
  getCompanies(filter: Filter): Observable<Entities> {
    return this.apiService.get(`api/v1/entities/`, filter);
  }

  /**
   * @summary Get company by id
   * @param id - id of company
   */
  getCompanyById(id: number): Observable<Company> {
    return this.apiService.get(`api/v1/entities/${id}/`);
  }

  /**
   * @summary Get country list
   */
  getCountryList(): Observable<Company []> {
    return this.apiService.get(`api/v1/countries/`);
  }

  /**
   * @summary Get region list
   */
  getRegions(): Observable<Region []> {
    return this.apiService.get(`api/v1/regions/`);
  }

  /**
   * @summary Set filter
   * @param filter - filter data
   */
  setFilter(filter: Filter) {
    this.filtersSubject.next(filter);
  }

  /**
   * @summary Get evaluations for novelty
   * @param id - id of company
   */
  getEvaluations(id: number): Observable<Evaluations> {
    return this.apiService.get(`api/v1/evaluations/${id}/`);
  }

  /**
   * @summary Set user radar
   * @param radar - number of radar
   */
  setUsedRadar(radar: number): Observable<{ message: string }> {
    return this.apiService.post(`api/v1/users/set_used_radar/`, {radar: radar});
  }

  /**
   * @summary Get category list from server
   */
  getCategory() {
    return this.apiService.get(`api/v1/tag_categories/`);
  }

  /**
   * @summary Get heatmap data from server
   * @param filter - filter data
   */
  getHeatmap(filter: HeatmapFilter): Observable<HeatMap> {
    return this.apiService.get(`api/v1/entities/heatmap/`, filter);
  }

  /**
   * @summary Get similar company list
   * @param id - id of company
   */
  getSimilar(id: number) {
    this.apiService.get(`api/v1/entities/${id}/similarity/`)
      .subscribe((data: Similars) => {
        this.similarSubject.next(data.results);
      });
  }

  /**
   * @summary Set similar company list
   * @param companies - company list
   */
  setSimilar(companies: Company[]) {
    this.similarSubject.next(companies);
  }

  /**
   * @summary Get current similar company list
   * @param id - id of company
   */
  getCurrentSimilar(id: number) {
    if (this.similarSubject.value) {
      this.similarSubject.next(this.similarSubject.value);
    } else {
      this.getSimilar(id);
    }
  }

  /**
   * @summary Get list of  company according to search name
   * @param searchData - search data
   */
  getSearchCompanies(searchData: SearchData): Observable<SearchList> {
    return this.apiService.get(`api/v1/search/`, searchData);
  }

  /**
   * @summary Set search list
   * @param searchList - search list
   */
  setSearchList(searchList: SearchList) {
    this.searchSubject.next(searchList);
  }

  /**
   * @summary Set companies list
   * @param companies - company list
   */
  setCompanies(companies: Company []) {
    this.companiesSubject.next(companies);
  }

  /**
   * @summary Set companies list
   * @param company - company data
   */
  setCompany(company: Company) {
    this.companySubject.next(company);
  }

  /**
   * @summary Get company name for auto completion
   * @param searchName - search name
   */
  getAutoCompletion(searchName: { qa: string }): Observable<{results: string []}> {
    return this.apiService.get(`api/v1/search/`, searchName);
  }

  /**
   * @summary Get countries lan and lng cordinats list
   */
  getLocations(): Observable<any> {
    return this.http.get(`/assets/countries-location.min.json`);
  }

  /**
   * @summary Get news of company
   * @param id - id of news
   */
  getNews(id: number): Observable<NewsList> {
    return this.apiService.get(`api/v1/entities/${id}/news/`);
  }

  /**
   * @summary Get csv file of companies list from server
   * @param filter - filter data
   */
  getFile(filter: Filter): Observable<any> {
    return this.apiService.getFile(`api/v1/entities/`, filter);
  }
}
