import {Component, OnInit} from '@angular/core';

/**
 * @summary News component
 */
@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  /**
   * @summary News component constructor
   */
  constructor() {
  }

  /**
   * @summary Run when News component init
   */
  ngOnInit() {
  }
}
