import {Directive, OnInit, OnDestroy, Output, EventEmitter, ElementRef} from '@angular/core';
import {Subscription} from 'rxjs';
import {tap, delay} from 'rxjs/internal/operators';
import {fromEvent} from 'rxjs';

/**
 * @summary Click outside directive
 */
@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnInit, OnDestroy {
  private listening: boolean;
  private globalClick: Subscription;

  @Output('clickOutside') clickOutside: EventEmitter<Object>;

  /**
   * @summary Click outside directive constructor
   * @param elRef - Angular element ref service
   */
  constructor(private elRef: ElementRef) {
    this.listening = false;
    this.clickOutside = new EventEmitter();
  }

  /**
   * Initialize the component and fetch initial data
   */
  ngOnInit() {
    this.globalClick = fromEvent(document, 'click')
      .pipe(delay(1), tap(() => {
        this.listening = true;
      }))
      .subscribe((event: MouseEvent) => {
        this.onGlobalClick(event);
      });
  }

  /**
   * Global click listener
   * @param event - Mouse event
   */
  onGlobalClick(event: MouseEvent) {
    if (event instanceof MouseEvent && this.listening === true) {
      if (this.isDescendant(this.elRef.nativeElement, event.target) === true) {
        this.clickOutside.emit({
          target: (event.target || null),
          value: false
        });
      } else {
        this.clickOutside.emit({
          target: (event.target || null),
          value: true
        });
      }
    }
  }

  /**
   * Check if html element was clicked
   * @param parent - Parent html element
   * @param child - Child html element
   */
  isDescendant(parent, child) {
    let node = child;
    while (node !== null) {
      if (node === parent) {
        return true;
      } else {
        node = node.parentNode;
      }
    }
    return false;
  }

  /**
   * Cleanup logic
   */
  ngOnDestroy() {
    if (this.globalClick) {
      this.globalClick.unsubscribe();
    }
  }
}
