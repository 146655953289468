import {Component, OnInit} from '@angular/core';
import {UserService} from '@shared/services/user.service';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {ConfirmComponent} from '@app/auth/confirm/confirm.component';
import {ToasterService} from 'angular2-toaster';

/**
 * @summary Auth component
 */
@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})

export class AuthComponent implements OnInit {
  signIn = true;

  /**
   * @summary Auth component constructor
   * @param userService - User service
   * @param toaster - Toaster service
   * @param route - User service
   * @param dialog - MatDialog service
   * @param router - Router service
   */
  constructor(private userService: UserService,
              private toaster: ToasterService,
              private route: ActivatedRoute,
              private router: Router,
              private dialog: MatDialog) {
    this.checkQueryParams();
  }

  /**
   * @summary Run when Auth component init
   */
  ngOnInit() {
    this.checkQueryParams();
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.reset_id && params.reset_key) {
        this.openReset(params.reset_id, params.reset_key);
      }
      if (params.confirm_id) {
        this.confirmVerify(params.confirm_id);
      }
      if (params.key) {
        localStorage.setItem('token', params.key);
        this.router.navigate(['/companies']);
      }
    });
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param resetId - id of reset
   * @param resetKey - key of reset
   */
  openReset(resetId, resetKey) {
    this.dialog.open(ConfirmComponent, {
      width: '400px',
      height: '310px',
      data: {
        resetId: resetId,
        resetKey: resetKey
      }
    });
  }

  /**
   * @summary Confirm registration
   * @param key - confirm key
   */
  confirmVerify(key: string) {
    this.userService.confirmVerify(key)
      .subscribe(res => {
        this.toaster.pop('success', res.detail);
        this.router.navigate(['/verify']);
      });
  }
}
