import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToasterService} from 'angular2-toaster';
import {CompaniesService} from '@shared/services/companies.service';
import {UserService} from '@shared/services/user.service';
import {ApiService} from '@shared/services/api.service';
import {CommonService} from '@shared/services/common.service';
import {User} from '@shared/common-interfaces/user-interface';
import {Radar} from '@shared/common-interfaces/radar-interface';

/**
 * @summary MyProfile component
 */
@Component({
  selector: 'app-my-profile',
  templateUrl: './my-profile.component.html',
  styleUrls: ['./my-profile.component.scss']
})
export class MyProfileComponent implements OnInit {
  @Output() closeProfile = new EventEmitter<boolean>();
  user: User = this.userService.getCurrentUser();
  passwordForm: FormGroup;
  radars: Radar [];
  editName: boolean;
  name: string;
  disabled: boolean;
  passwordOpen: boolean;

  /**
   * @summary MyProfile component constructor
   * @param userService - user service
   * @param companiesService - Companies service
   * @param apiService - Api service
   * @param formBuilder - formBuilder service
   * @param commonService - Common service
   * @param toaster - Toaster service
   */
  constructor(private userService: UserService,
              private companiesService: CompaniesService,
              private apiService: ApiService,
              private formBuilder: FormBuilder,
              private commonService: CommonService,
              private toaster: ToasterService) {
  }

  /**
   * @summary Run when MyProfile component init
   */
  ngOnInit() {
    this.getRadars();
    this.initForm();
  }

  /**
   * @summary Get radar list from server
   */
  getRadars() {
    if (this.user.is_admin) {
      this.companiesService.getRadars()
        .subscribe((radars: Radar []) => {
          this.radars = radars;
        });
    } else {
      this.radars = this.user.allowed_radars;
    }
  }

  /**
   * @summary Init form
   */
  initForm() {
    this.passwordForm = this.formBuilder.group({
      'new_password1': new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ]),
      'new_password2': new FormControl(null, [
        Validators.required,
        this.commonService.confirmPassword('new_password1')
      ])
    });
  }

  /**
   * @summary Close my profile window
   */
  close() {
    this.closeProfile.emit(false);
  }

  /**
   * @summary Left account
   */
  logOut() {
    this.userService.logout();
  }

  /**
   * @summary Edit user name
   */
  changeName() {
    this.editName = true;
    this.name = this.user.name;
  }

  /**
   * @summary Save new user name
   */
  saveName() {
    if (this.name === this.user.name) {
      return;
    }
    this.apiService.patch(`api/v1/users/${this.user.id}/`, {name: this.name})
      .subscribe((user: User) => {
        this.user.name = user.name;
        this.editName = false;
      });
  }

  /**
   * @summary Change password of user
   */
  changePassword() {
    this.disabled = !this.disabled;
    this.userService.changePassword(this.passwordForm.value)
      .subscribe((data: any) => {
        this.passwordOpen = false;
        this.toaster.pop('success', data.detail);
      });
  }
}
