import { Component, Input, OnInit } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import { MatRadioChange } from '@angular/material';

// Services
import {ApiService} from '@shared/services/api.service';
import { radar } from '@app/shared/unit-test-services/mock-user.service';

@Component({
  selector: 'app-company-comment',
  templateUrl: './company-comment.component.html',
  styleUrls: ['./company-comment.component.scss']
})
export class CompanyCommentComponent implements OnInit {
  @Input() id: number;
  @Input() userid: number;
  radartext: string;
  interestingTypes = [
    {id: 1, name:'Yes'},
    {id: 0, name:'No'}
  ];
  selectedInteresting: number;
  isDisabled: boolean = true;
  textareaComment: String = "";
  isCommentSaving: boolean = false;
  isCommentFeedback: boolean = false;

  constructor(private apiService: ApiService,
              private http: HttpClient) { }

  ngOnInit() {
    this.radartext = document.getElementById("currentuseractiveradar").textContent;
  }

  buttonActive(comment: string) {
    if (comment.trim().length) {
      if(this.selectedInteresting) {
        this.isDisabled = false
      }
    } else {
      this.isDisabled = true
    }
  }

  radioChange(event: MatRadioChange) {
    if (this.textareaComment.trim().length) {
      this.isDisabled = false
    } else {
      this.isDisabled = true
    }
  }

  /**
   * @summary Set user comment, radar_id, user_id, entity_id, inretesting type
   * @param radar - name of radar (backend will convert from text to radar_id)
   * @param entity - number of entity
   * @param user_id - number of user id
   * @param comment - text of comment
   * @param interesting - type of interesting (number)
   */
  setUserComment(radar: string, entity: number, userid: number, comment: string, interesting: number): Observable<{ message: string }> {
    let data = {
      radar: radar, 
      entity: entity, 
      userid: userid, 
      comment: comment, 
      interesting: interesting
    };   
    let body = JSON.stringify(data )
    // return this.apiService.post(`api/v1/comment/set_comment`, {radar: radar, entity: entity, userid: userid, comment: comment, interesting: interesting});
    // return this.apiService.post(`api/v1/comment/set_comment`, {radar: radar});
    return this.apiService.post(`api/v1/comment/set_comment`, data);

  }

  processComment(comment: string) {
    console.log(this.id, this.userid, this.radartext, comment, this.selectedInteresting)
    this.isCommentSaving = true;

    this.setUserComment(this.radartext, this.id, this.userid, comment, this.selectedInteresting)
      .subscribe(
        (res: any) => {
          console.log(res);
          this.isCommentSaving = false;
          this.isCommentFeedback = true;
        });

    setTimeout(()=>{
      this.isCommentSaving = false;
      this.isCommentFeedback = true;
    }, 3000);
    setTimeout(()=>{
      this.isCommentFeedback = false;
    }, 7000);
  }

}
