/**
 * @summary MySearch service
 */
import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {ApiService} from '@shared/services/api.service';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';
import {Filter} from '@shared/models/filter.model';

@Injectable()
export class MySearchService {
  companyFilterSubject = new Subject<Filter>();
  mySearchSubject = new Subject<MySearch[]>();
  selectIdSubject = new Subject<number[]>();

  /**
   * @summary MySearch service constructor
   * @param apiService - Api service
   */
  constructor(private apiService: ApiService) {
  }

  /**
   * @summary Save my search in MySearch list
   * @param search - search data
   */
  saveMySearch(search: MySearch): Observable<any> {
    return this.apiService.post(`api/v1/search_lines/`, search);
  }

  /**
   * @summary Get MySearch list from server
   * @param userId - id of user
   */
  getMySearches(userId: number): Observable<MySearch[]> {
    return this.apiService.get(`api/v1/users/${userId}/search_lines/`);
  }

  /**
   * @summary Set MySearch list
   * @param mySearches - mySearch list
   */
  setMySearches(mySearches: MySearch []) {
    this.mySearchSubject.next(mySearches);
  }

  /**
   * @summary Set MySearch list
   * @param selectId - id list
   */
  setSelectId(selectId: number []) {
    this.selectIdSubject.next(selectId);
  }

  /**
   * @summary Edit MySearch value
   * @param search - search data
   * @param id - search id
   */
  editSearch(search: any, id: number): Observable<any> {
    return this.apiService.put(`api/v1/search_lines/${id}/`, search);
  }

  /**
   * @summary Edit MySearch list
   * @param data - mySearches data
   */
  editMySearches(data: MySearch []) {
    const searches = data;
    let dataEl;
    for (let i = 0; i < data.length; i++) {
      dataEl = JSON.parse(data[i].text);
      searches[i].text = dataEl;
      searches[i].link = this.generateLink(searches[i]);
    }
    return searches;
  }

  /**
   * @summary Generate link for routing
   * @param search - search element
   */
  generateLink(search: MySearch) {
    if (search.text.total_raised_max >= 0) {
      return '/companies';
    } else if (search.text.amount_min >= 0) {
      return '/investments';
    } else {
      return '/company-news';
    }
  }

  /**
   * @summary Add company to my lists
   * @param idList - id list
   */
  deleteSearch(idList: number []): Observable<any> {
    return this.apiService.delete(`api/v1/search_lines/bulk_delete/`, null, {'pk_list': idList});
  }
}
