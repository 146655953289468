import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {Radar} from '@shared/common-interfaces/radar-interface';
import {UserService} from '@shared/services/user.service';
import {CompaniesService} from '@shared/services/companies.service';
import {ToasterService} from 'angular2-toaster';
import {Router} from '@angular/router';

/**
 * @summary ChangeRadar component
 */
@Component({
  selector: 'app-change-radar',
  templateUrl: './change-radar.component.html',
  styleUrls: ['./change-radar.component.scss']
})
export class ChangeRadarComponent implements OnInit {
  @Output() page = new EventEmitter<{ page: string, id: number }>();
  radar: Radar;
  radarId: number;

  /**
   * @summary ChangeRadar component constructor
   * @param dialog - MatDialog service
   * @param userService - user service
   * @param companiesService - Companies service
   * @param toaster - toaster service
   * @param data - data value
   * @param router - data value
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: { radars: Radar [], id: number, page: string, invest: boolean },
              private userService: UserService,
              private companiesService: CompaniesService,
              private toaster: ToasterService,
              private dialog: MatDialog,
              private router: Router) {
  }

  /**
   * @summary Run when Search component init
   */
  ngOnInit() {
  }

  /**
   * @summary Change radar select value
   */
  radarChange() {
    this.radar = this.setRadar();
    this.userService.setCurrentRadar(this.radar);
    this.router.navigate(['/search'],
      {queryParams: {id: this.data.id, page: this.data.page, invest: this.data.invest}}
    );
    this.dialog.closeAll();
  }

  /**
   * @summary Set radar value
   */
  setRadar() {
    for (const radar of this.data.radars) {
      if (radar.id === this.radarId) {
        return radar;
      }
    }
  }

  /**
   * @summary Close dialog window
   */
  close() {
    this.dialog.closeAll();
  }
}
