import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '@app/auth/auth-guard.service';
import {InvestmentsSearchComponent} from '@app/dashboard/investments/investments-search/investments-search.component';
import {DashboardComponent} from '@app/dashboard/dashboard.component';
import {CompaniesComponent} from '@app/dashboard/companies/companies.component';
import {InvestmentsComponent} from '@app/dashboard/investments/investments.component';
import {CompaniesSearchComponent} from '@app/dashboard/companies/companies-search/companies-search.component';
import {CompaniesHeatmapComponent} from '@app/dashboard/companies/companies-heatmap/companies-heatmap.component';
import {InvestmentsHeatmapComponent} from '@app/dashboard/investments/investments-heatmap/investments-heatmap.component';
import {SearchComponent} from '@app/dashboard/search/search.component';
import {MyListsComponent} from '@app/dashboard/my-lists/my-lists.component';
import {SimilarCompanyComponent} from '@app/dashboard/companies/similar-company/similar-company.component';
import {MySearchesComponent} from '@app/dashboard/my-searches/my-searches.component';
import {NewsComponent} from '@app/dashboard/news/news.component';
import {NewsSearchComponent} from '@app/dashboard/news/news-search/news-search.component';
import {NewsHeatmapComponent} from '@app/dashboard/news/news-heatmap/news-heatmap.component';
import {PageNotFoundComponent} from '@app/page-not-found/page-not-found.component';

const DashboardRoutes: Routes = [
  {
    path: '', component: DashboardComponent, canActivate: [AuthGuard], data: {name: 'dashboard'}, children: [
      {path: 'my-lists', component: MyListsComponent, data: {name: 'my-lists'}},
      {path: 'my-searches', component: MySearchesComponent, data: {name: 'my-searches'}},
      {path: 'search', component: SearchComponent, data: {name: 'search'}},
      {
        path: 'companies', component: CompaniesComponent, data: {name: 'comp'}, children: [
          {path: '', component: CompaniesSearchComponent, data: {name: 'companies'}},
          {path: 'heatmap', component: CompaniesHeatmapComponent, data: {name: 'heatmap'}},
          {path: ':id', component: SimilarCompanyComponent, data: {name: 'company-info'}},
        ]
      },
      {
        path: 'investments', component: InvestmentsComponent, data: {name: 'invest'}, children: [
          {path: '', component: InvestmentsSearchComponent, data: {name: 'investments'}},
          {path: 'heatmap', component: InvestmentsHeatmapComponent, data: {name: 'heatmap'}}
        ]
      },
      {
        path: 'news', component: NewsComponent, data: {name: 'news'}, children: [
          {path: '', component: NewsSearchComponent, data: {name: 'company-news-search'}},
          {path: 'heatmap', component: NewsHeatmapComponent, data: {name: 'company-news-heat-map'}}
        ]
      },
      {path: 'not-found', component: PageNotFoundComponent},
      {path: '**', redirectTo: 'not-found'}
    ]
  },
];

@NgModule({
  imports: [RouterModule.forChild(DashboardRoutes)],
  exports: [RouterModule],
  providers: []
})

export class DashboardRoutingModule {
}
