import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';

import {AuthGuard} from './auth-guard.service';
import {AuthComponent} from '@app/auth/auth.component';
import {VerifyComponent} from '@app/verify/verify.component';

const AuthRoutes: Routes = [
  {path: '', component: AuthComponent, canActivate: [AuthGuard], data: {name: 'auth'}},
  {path: 'verify', component: VerifyComponent, data: {name: 'verify'}},
];

@NgModule({
  imports: [RouterModule.forChild(AuthRoutes)],
  exports: [RouterModule],
  providers: [
    AuthGuard
  ]
})

export class AuthRoutingModule {
}
