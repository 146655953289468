import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserService} from '@shared/services/user.service';
import {finalize} from 'rxjs/operators';
import {ToasterService} from 'angular2-toaster';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';

/**
 * @summary Recovery component
 */
@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss']
})
export class RecoveryComponent implements OnInit {
  resetForm: FormGroup;
  disabled: boolean;
  send = this.data.send;

  /**
   * @summary Recovery component constructor
   * @param userService - user service
   * @param toaster - Toaster service
   * @param dialog - MatDialog service
   * @param data - data elem
   */
  constructor(private userService: UserService,
              private toaster: ToasterService,
              private dialog: MatDialog,
              @Inject(MAT_DIALOG_DATA)
              public data: { send: boolean }) {
  }

  /**
   * @summary Run when Recovery component init
   */
  ngOnInit() {
    this.initRecoveryForm();
  }

  /**
   * @summary Init form
   */
  initRecoveryForm() {
    this.resetForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ])
    });
  }

  /**
   * @summary Reset password
   */
  onResetPassword() {
    this.disabled = !this.disabled;
    this.userService.resetPassword(this.resetForm.value)
      .pipe(finalize(() => this.disabled = !this.disabled))
      .subscribe((data: { detail: string }) => {
        this.toaster.pop('success', data.detail);
        this.send = true;
      });
  }

  /**
   * @summary Close dialog window
   */
  close() {
    this.dialog.closeAll();
  }
}
