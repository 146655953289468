import {Component, EventEmitter, OnDestroy, OnInit, Output} from '@angular/core';
import {Subscription} from 'rxjs';
import {SearchData} from '@shared/models/search-data.model';
import {UserService} from '@shared/services/user.service';
import {CompaniesService} from '@shared/services/companies.service';
import {Radar} from '@shared/common-interfaces/radar-interface';
import {CommonService} from '@shared/services/common.service';
import {User} from '@shared/common-interfaces/user-interface';
import {SearchList} from '@shared/common-interfaces/search-list-interface';

/**
 * @summary Header component
 */
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit, OnDestroy {
  @Output() closeSidebar = new EventEmitter<boolean>();
  radarSubscription: Subscription;
  user: User = this.userService.getCurrentUser();
  searchData: SearchData = new SearchData;
  autoName: { qa: string } = {qa: null};
  searchList: SearchList;
  autoCompletionList: string[];
  searchValue: string;
  radars: Radar [];
  radar: Radar;
  openProfile = false;
  openUser = false;
  open = false;

  /**
   * @summary Header component constructor
   * @param userService - user service
   * @param companiesService - Companies service
   * @param commonService - Common service
   */
  constructor(private userService: UserService,
              private companiesService: CompaniesService,
              private commonService: CommonService) {
  }

  /**
   * @summary Run when Header component init
   */
  ngOnInit() {
    this.getRadars();
    this.radarChange();
  }

  /**
   * @summary Get radar list from server
   */
  getRadars() {
    if (this.user.is_admin) {
      this.companiesService.getRadars()
        .subscribe((radars: Radar []) => {
          this.radars = radars;
          this.checkRadar();
        });
    } else {
      this.radars = this.user.allowed_radars;
      this.checkRadar();
    }
  }

  /**
   * @summary Check what radar selected
   */
  checkRadar() {
    if (!this.user.radar) {
      this.radar = this.user.allowed_radars[0];
    }
    for (const radar of this.radars) {
      if (radar.id === this.user.radar) {
        this.radar = radar;
      }
    }
  }

  /**
   * @summary Init get companies when radar changing
   */
  radarChange() {
    this.radarSubscription = this.userService.currentRadarSubject
      .subscribe((radar: Radar) => {
          if (!radar) {
            return;
          } else {
            this.setRadar(radar);
          }
        }
      );
  }

  /**
   * @summary Set value of name foe search
   */
  onSearchName() {
    this.getSearchCompanies();
    this.getAutoCompletion();
  }

  /**
   * @summary Get company list according search name
   */
  getSearchCompanies() {
    if (!this.searchData.q) {
      this.searchList = null;
      this.companiesService.setSearchList(this.searchList);
      return;
    }
    this.companiesService.getSearchCompanies(this.searchData)
      .subscribe((data: SearchList) => {
        this.searchList = data;
        this.searchList.q = this.searchData.q;
        this.companiesService.setSearchList(this.searchList);
      });
  }

  /**
   * @summary Get company list according auto completion
   */
  getAutoCompletion() {
    if (!this.searchData.q) {
      this.autoCompletionList = [];
      return;
    }
    this.autoName.qa = this.searchData.q;
    this.companiesService.getAutoCompletion(this.autoName)
      .subscribe((res: {results: string []}) => {
        this.autoCompletionList = res.results;
      });
  }

  /**
   * @summary Set name for search
   * @param name - value of name
   */
  setName(name: string) {
    this.searchData.q = name;
    this.getSearchCompanies();
    this.autoCompletionList = [];
  }

  /**
   * @summary Left account
   */
  logOut() {
    this.userService.logout();
  }

  /**
   * @summary Set client in radar
   * @param radar - radar data
   */
  setRadar(radar: Radar) {
    this.radar = radar;
    this.open = false;
    this.setUsedRadar(radar.id);
  }

  /**
   * @summary Set date of radar
   * @param radarId - radar id
   */
  setUsedRadar(radarId: number) {
    this.companiesService.setUsedRadar(radarId)
      .subscribe((data: {message: string}) => {
        this.getUser();
      });
  }

  /**
   * @summary Get user after change radar
   */
  getUser() {
    this.userService.getUser()
      .subscribe((user: User) => {
        this.user = user;
        this.userService.setCurrentUser(user);
        this.commonService.setClose(true);
      });
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.radarSubscription);
  }
}
