import {Component, Input, OnInit} from '@angular/core';
import {CompaniesService} from '@shared/services/companies.service';
import {News, NewsList} from '@shared/common-interfaces/news-list-interface';

/**
 * @summary News component
 */
@Component({
  selector: 'app-company-news',
  templateUrl: './company-news.component.html',
  styleUrls: ['./company-news.component.scss']
})

export class CompanyNewsComponent implements OnInit {
  @Input() id: number;
  title = ['title', 'url', 'date', 'source', 'region'];
  newsList: News [] = [];

  /**
   * @summary News component constructor
   * @param companiesService - Companies service
   */
  constructor(private companiesService: CompaniesService) {
  }

  /**
   * @summary Run when News component init
   */
  ngOnInit() {
    this.getNews();
  }

  /**
   * @summary Get news of company
   */
  getNews() {
    this.companiesService.getNews(this.id)
      .subscribe((data: NewsList) => {
        this.newsList = data.results;
      });
  }
}
