import { Component } from '@angular/core';

/**
 * @summary Verify component
 */
@Component({
  selector: 'app-verify',
  templateUrl: './verify.component.html',
  styleUrls: ['./verify.component.scss']
})
export class VerifyComponent {}
