import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material';
import {ToasterService} from 'angular2-toaster';

import {environment} from '../../../../environments/environment';
import * as FileSaver from 'file-saver';
import {ActivatedRoute, Params} from '@angular/router';

// Components
import {NewsDetailComponent} from '@app/dashboard/news/news-search/news-detail/news-detail.component';

// Services
import {MySearchService} from '@shared/services/my-search-service';
import {NewsService} from '@shared/services/news.service';
import {CompaniesService} from '@shared/services/companies.service';
import {CommonService} from '@shared/services/common.service';
import {UserService} from '@shared/services/user.service';

// Interface and models
import {NewsFilter} from '@shared/models/news-filter.model';
import {News, NewsList} from '@shared/common-interfaces/news-list-interface';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';
import {Company} from '@shared/common-interfaces/company-interface';
import {Th} from '@shared/common-interfaces/th-list-interface';

@Component({
  selector: 'app-news-search',
  templateUrl: './news-search.component.html',
  styleUrls: ['./news-search.component.scss']
})

export class NewsSearchComponent implements OnInit, OnDestroy {
  closeSubscription: Subscription;
  filterSubscription: Subscription;
  filters: NewsFilter = null;
  companies: Company [] = [];
  newsList: News [] = [];
  thList: Th [] = [
    {name: 'title', sort: 'title', flag: false},
    {name: 'description', sort: 'description', flag: false},
    {name: 'published', sort: 'publish_date', flag: false},
    {name: 'company', sort: 'entity__name', flag: false},
    {name: 'url', sort: 'url', flag: false},
    {name: 'source', sort: 'media__name', flag: false},
    {name: 'region', sort: 'region__name', flag: false}
  ];
  pageSize: number;
  pageSizes = [10, 25, 50];
  lengthList: number;
  pageIndex = 0;
  region: string;
  openPageSize = false;
  search: MySearch;
  active: number;
  user = this.userService.getCurrentUser();
  name: string;
  url = environment.apiUrl;

  /**
   * @summary CompaniesSearch component constructor
   * @param dialog - MatDialog service
   * @param userService - User service
   * @param companiesService - Companies service
   * @param newsService - News service
   * @param commonService - Common service
   * @param toaster - Toaster service
   * @param mySearchService - MySearch service
   * @param route - ActivatedRoute service
   */
  constructor(private dialog: MatDialog,
              private companiesService: CompaniesService,
              private newsService: NewsService,
              private commonService: CommonService,
              private userService: UserService,
              private toaster: ToasterService,
              private mySearchService: MySearchService,
              private route: ActivatedRoute) {
  }

  /**
   * @summary Run when CompaniesSearch component init
   */
  ngOnInit() {
    this.setFilter();
    this.closeChange();
    this.checkQueryParams();
  }

  /**
   * @summary Set filters to get news list
   */
  setFilter() {
    this.filterSubscription = this.newsService.filtersSubject
      .subscribe((filter: NewsFilter) => {
          this.filters = filter;
          this.checkPage();
          this.getNews();
        }
      );
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe((res: boolean) => {
        this.getNews();
      });
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.id) {
        this.newsService.getNewsById(+params.id)
          .subscribe((news: News) => {
            this.openDetailById(news);
          });
      }
    });
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param news - News data
   */
  openDetailById(news: News) {
    this.dialog.open(NewsDetailComponent, {
      width: '920px',
      data: {
        newsList: [],
        news: news,
        i: null
      }
    });
  }

  /**
   * @summary Get company list from server
   */
  getNews() {
    if (!this.user.allowed_radars.length && !this.user.is_admin) {
      return;
    }
    this.newsService.getNews(this.filters)
      .subscribe((data: NewsList) => {
          this.filters.page = this.pageIndex + 1;
          this.newsList = data.results;
          this.lengthList = data.count;
          this.pageSize = data.results ? data.results.length : 25;
        }
      );
  }

  /**
   * @summary Check number of page on zero
   */
  checkPage() {
    if (this.filters.page > 0) {
      this.filters.page = 0;
    }
    this.pageIndex = 0;
  }

  /**
   * @summary Change page of company list
   * @param event - event of pagination
   */
  changePage(event: any) {
    this.filters.page = event.pageIndex + 1;
    this.pageIndex = event.pageIndex;
    this.getNews();
  }

  /**
   * @summary Set number of company on page
   * @param value - page size
   */
  setPageSize(value: number) {
    this.filters.page_size = value;
    this.checkPage();
    this.getNews();
    this.openPageSize = false;
  }

  /**
   * @summary Set value name for search filter
   */
  searchName() {
    this.checkPage();
    this.getNews();
  }

  /**
   * @summary Save company list in csv file
   */
  saveCsv() {
    this.filters.format = 'csv';
    this.newsService.getFile(this.filters)
      .subscribe((res: any) => {
        FileSaver.saveAs(res.body, this.commonService.getFileName(res));
      });
  }

  /**
   * @summary Save company search list
   */
  onSave() {
    this.search = {
      name: this.commonService.createDate('news_search'),
      text: JSON.stringify(this.filters),
      user: this.user.id
    };
    this.mySearchService.saveMySearch(this.search)
      .subscribe(res => {
        this.toaster.pop('success', 'Search list added');
      });
  }

  /**
   * @summary Sort table column by min and max
   * @param th - page size
   * @param i - index
   */
  sort(th: Th, i: number) {
    this.active = i;
    for (const elem of this.thList) {
      if (elem.name !== th.name) {
        elem.flag = false;
      }
    }
    let sort = '';
    th.flag = !th.flag;
    if (!th.flag) {
      sort = '-';
    }
    if (th.sort === 'company' || th.sort === 'source' || th.sort === 'region') {
      sort = sort + th.sort + '__name' + sort.slice(0, -1);
    }
    this.filters.ordering = sort + th.sort;
    this.getNews();
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param index - index of news
   */
  openDetail(index: number) {
    this.dialog.open(NewsDetailComponent, {
      width: '920px',
      data: {
        newsList: this.newsList,
        news: this.newsList[index],
        i: index
      }
    });
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.filterSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }

}
