import { Component, OnInit } from '@angular/core';

/**
 * @summary Investments component
 */
@Component({
  selector: 'app-investments',
  templateUrl: './investments.component.html',
  styleUrls: ['./investments.component.scss']
})

export class InvestmentsComponent implements OnInit {
  /**
   * @summary Investments component constructor
   */
  constructor() { }

  /**
   * @summary Run when Investments component init
   */
  ngOnInit() {
  }

}
