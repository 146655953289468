import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {CommonService} from '@shared/services/common.service';
import {UserService} from '@shared/services/user.service';
import {finalize} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ConfirmComponent} from '@app/auth/confirm/confirm.component';
import {RecoveryComponent} from '@app/auth/recovery/recovery.component';
import {MatDialog} from '@angular/material';

/**
 * @summary Sign up component
 */
@Component({
  selector: 'app-sing-up',
  templateUrl: './sing-up.component.html',
  styleUrls: ['./sing-up.component.scss']
})
export class SingUpComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  signUpForm: FormGroup;
  disabled: boolean;

  /**
   * @summary Sign up component constructor
   * @param formBuilder - FormBuilder service
   * @param router - Router service
   * @param commonService - Common service
   * @param userService - User service
   * @param dialog - MatDialog service
   */
  constructor(private formBuilder: FormBuilder,
              private commonService: CommonService,
              private userService: UserService,
              private router: Router,
              private dialog: MatDialog) {
  }

  /**
   * @summary Run when sign up component init
   */
  ngOnInit() {
    this.initForm();
  }

  /**
   * @summary Init form
   */
  initForm() {
    this.signUpForm = this.formBuilder.group({
      'name': new FormControl(null, [
        Validators.required
      ]),
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password1': new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ]),
      'password2': new FormControl(null, [
        Validators.required,
        this.commonService.confirmPassword('password1')
      ])
    });
  }

  /**
   * @summary Register new user
   */
  onSingUp() {
    this.disabled = !this.disabled;
    this.userService.signUp(this.signUpForm.value)
      .pipe(finalize(() => this.disabled = !this.disabled))
      .subscribe(res => {
        this.openSingIn();
        this.openSuccess();
      });
  }

  /**
   * @summary Open sign in page
   */
  openSingIn() {
    this.close.emit(false);
  }

  /**
   * @summary Open dialog window of Recovery component
   */
  openSuccess() {
    this.dialog.open(RecoveryComponent, {
      width: '600px',
      height: '400px',
      data: {
        send: true
      }
    });
  }
}
