import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {Options} from 'ng5-slider';

/**
 * @summary RangeSlider component
 */
@Component({
  selector: 'app-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss']
})

export class RangeSliderComponent implements OnInit, OnChanges {
  @Input('ceil') ceil: number;
  @Input('step') step: number;
  @Input('range') range: {
    minValue: number,
    maxValue: number
  };
  @Output() value = new EventEmitter<{ minValue: number, maxValue: number }>();
  options: Options;

  /**
   * @summary RangeSlider component constructor
   */
  constructor() {
  }

  /**
   * @summary Run when RangeSlider component init
   */
  ngOnInit() {
  }

  /**
   * @summary Run when elem change
   */
  ngOnChanges() {
    if (this.ceil) {
      this.changeOptions();
    }
  }

  /**
   * @summary Change value of range slider
   */
  changeValue() {
    this.value.emit(this.range);
  }

  /**
   * @summary Change max and min value
   */
  changeOptions() {
    this.options = {
      floor: 0,
      ceil: this.ceil,
      step: this.step,
      hideLimitLabels: true,
    };
  }
}
