/**
 * @summary MyLists service
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {Company} from '@shared/common-interfaces/company-interface';
import {ApiService} from '@shared/services/api.service';

@Injectable()
export class MyListsService {
  myListSubject = new BehaviorSubject<Company []>(null);

  /**
   * @summary MyLists service constructor
   * @param apiService - Api service
   */
  constructor(private apiService: ApiService) {
  }

  /**
   * @summary Add company to my lists
   * @param userId - id of user
   * @param entity - id of entity
   */
  addToMyList(userId: number, entity: { entity_id: number }): Observable<any> {
    return this.apiService.post(`api/v1/users/${userId}/add_favorite_entity/`, entity);
  }

  /**
   * @summary Add company to my lists
   * @param userId - id of user
   * @param entity - id of entity
   */
  deleteFromMyList(userId: number, entity: { entity_id: number }): Observable<any> {
    return this.apiService.post(`api/v1/users/${userId}/remove_favorite_entity/`, entity);
  }

  /**
   * @summary Set my list of companies
   * @param companies - company list
   */
  setMyList(companies: Company []) {
    this.myListSubject.next(companies);
  }

  /**
   * @summary Get my lists of company
   * @param userId - id of user
   */
  getMyList(userId: number): Observable<Company []> {
    return this.apiService.get(`api/v1/users/${userId}/favorite_entities`);
  }
}
