/**
 * @summary Common service
 */
import {Injectable, OnInit} from '@angular/core';
import {BehaviorSubject, Subject, Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {FormControl} from '@angular/forms';
import * as domtoimage from 'dom-to-image';
import * as FileSaver from 'file-saver';
import {ApiService} from '@shared/services/api.service';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {MaxAmount} from '@shared/common-interfaces/max-amount-interface';

/**
 * @summary Common service
 */
@Injectable()
export class CommonService implements OnInit {
  filtersHeatMapSubject = new BehaviorSubject<HeatmapFilter>(new HeatmapFilter());
  stepChange = new Subject<MaxAmount>();
  closeChange = new Subject<boolean>();
  list = this.getList();

  /**
   * @summary Common service constructor
   * @param route - Activated route service
   * @param apiService - Api service
   */
  constructor(private route: ActivatedRoute,
              private apiService: ApiService) {
  }

  /**
   * @summary Run when Common service init
   */
  ngOnInit() {
  }

  /**
   * @summary Set heat map filter
   * @param filter - filter data
   */
  setHeatMapFilter(filter: HeatmapFilter) {
    this.filtersHeatMapSubject.next(filter);
  }

  /**
   * @summary Check if subscription is defined
   * @param subscription - subscription elem
   */
  checkSubscription(subscription: Subscription) {
    if (subscription) {
      subscription.unsubscribe();
    }
  }

  /**
   * @summary Check if subscription is defined
   * @param close - set close modal window
   */
  setClose(close: boolean) {
    this.closeChange.next(close);
  }

  /**
   * @summary Create step list
   */
  getList() {
    let num = 10;
    const array = [];
    array.push(num);
    while (num < 100000000000) {
      num *= 10;
      array.push(num);
    }
    return array;
  }

  /**
   * @summary Set name of step
   * @param amount -  max value
   */
  setStep(amount: number) {
    if (amount < 1000000) {
      return {step: 1000, name: 'k'};
    } else {
      return {step: 1000000, name: 'mio'};
    }
  }

  /**
   * @summary Set max value of range slider
   * @param totalRaised - total raised
   * @param maxEmployees - max employees value
   * @param amount - amount value
   */
  setAmount(totalRaised: number, maxEmployees: number, amount: number) {
    const maxAmount: MaxAmount = {
      totalRaised: + totalRaised,
      employees: + maxEmployees,
      amount: amount
    };
    this.stepChange.next(maxAmount);

  }

  /**
   * @summary Create list of point for round
   * @param num -  step value
   */
  generateList(num?: number) {
    let array = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    if (num) {
      array = array.map((item: number) => {
        return item * num;
      });
    }
    return array;
  }

  /**
   * @summary Set round max value for range slider
   * @param amount -  number value
   */
  round(amount: number) {
    let result, step, number;
    for (let i = 0; i < this.list.length; i++) {
      if (amount < this.list[i]) {
        result = this.generateList(this.list[--i]);
        break;
      }
    }
    for (const elem of result) {
      if (amount < elem) {
        number = elem;
        step = amount < 1000000 ? 1000 : 1000000;
        break;
      }
    }
    return {forUser: number / step, forFilter: number};
  }

  /**
   * @summary Get file name from response
   * @param res - Response
   */
  getFileName(res: any) {
    let fileName = '';
    const disposition = res.headers.get('Content-Disposition');
    if (disposition && disposition.indexOf('attachment') !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/['"]/g, '');
      }
    }
    return fileName;
  }

  /**
   * @summary Check confirm password
   * @param otherControlName - password value
   */
  confirmPassword(otherControlName: string) {
    let thisControl: FormControl;
    let otherControl: FormControl;
    return function matchOtherValidate(control: FormControl) {
      if (!control.parent) {
        return null;
      }
      if (!thisControl) {
        thisControl = control;
        otherControl = control.parent.get(otherControlName) as FormControl;
        if (!otherControl) {
          throw new Error('matchOtherValidator(): other control is not found in parent group');
        }
        otherControl.valueChanges.subscribe(() => {
          thisControl.updateValueAndValidity();
        });
      }

      if (!otherControl) {
        return null;
      }

      if (otherControl.value !== thisControl.value) {
        return {
          matchOther: true
        };
      }
      return null;
    };
  }

  /**
   * @summary Save heat map to png
   * @param elem - DOM element
   */
  savePng(elem: any) {
    domtoimage.toPng(elem)
      .then((dataUrl) => {
        FileSaver.saveAs(dataUrl, 'heat-map.png');
      })
      .catch((error) => {
        console.error('oops, something went wrong!', error);
      });
  }

  /**
   * @summary Get id from params
   * @param params - params value
   */
  getParamsId(params: string) {
    const invest = params.slice(0, 10);
    let id;
    if (invest === 'investment') {
      id = +params.slice(10);
      return id;
    } else {
      return +params;
    }
  }

  /**
   * @summary Edit date to format
   * @param title - date value
   */
  createDate(title: string) {
    const date = new Date();
    const day = date.getDate() > 9 ? date.getDate() : '0' + date.getDate();
    const month = date.getMonth() + 1 > 9 ? date.getMonth() + 1 : '0' + (date.getMonth() + 1);
    const hours = date.getHours() > 9 ? date.getHours() : '0' + date.getHours();
    const minutes = date.getMinutes() > 9 ? date.getMinutes() : '0' + date.getMinutes();
    const seconds = date.getSeconds() > 9 ? date.getSeconds() : '0' + date.getSeconds();
    return `${title}_${day}-${month}-${date.getFullYear()}_${hours}-${minutes}-${seconds}`;
  }

  /**
   * @summary Get tag list
   */
  getTagList() {
    return this.apiService.get(`api/v1/tags/`);
  }
}
