import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, PageEvent} from '@angular/material';
import {Subscription} from 'rxjs';

// Components
import {RemoveMyListComponent} from '@app/dashboard/my-lists/remove-my-list/remove-my-list.component';
import {CompanyInfoComponent} from '@app/dashboard/companies/company-info/company-info.component';

// Services
import {MyListsService} from '@shared/services/my-lists.service';
import {UserService} from '@shared/services/user.service';
import {CommonService} from '@shared/services/common.service';

// Interface and models
import {Company} from '@shared/common-interfaces/company-interface';

/**
 * @summary MyLists component
 */
@Component({
  selector: 'app-my-lists',
  templateUrl: './my-lists.component.html',
  styleUrls: ['./my-lists.component.scss']
})

export class MyListsComponent implements OnInit, OnDestroy {
  myListSubscription: Subscription;
  user = this.userService.getCurrentUser();
  companies: Company [] = [];
  searchName: string;
  theadList = ['', 'name', 'url', 'country', 'city', 'founded in', 'employees', 'total raised, $'];
  pageEvent: PageEvent = new PageEvent();
  openPagination = false;
  pageSize = 25;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  page = 1;

  /**
   * @summary MyLists component constructor
   * @param userService - user service
   * @param myListsService - MyLists service
   * @param dialog - MatDialog service
   * @param commonService - Common service
   */
  constructor(private myListsService: MyListsService,
              private userService: UserService,
              private dialog: MatDialog,
              private commonService: CommonService) {
  }

  /**
   * @summary Run when MyLists component init
   */
  ngOnInit() {
    this.getMyList();
    this.setMyList();
  }

  /**
   * @summary Get my lists of company from server
   */
  getMyList() {
    this.myListsService.getMyList(this.user.id)
      .subscribe((companies: Company []) => {
        this.companies = companies;
        this.onPagination();
        this.myListsService.setMyList(companies);
      });
  }

  /**
   * @summary Set my list of companies after change
   */
  setMyList() {
    this.myListSubscription = this.myListsService.myListSubject
      .subscribe((data: Company []) => {
          if (!data) {
            return;
          }
          this.companies = data;
        }
      );
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param id - id of  company
   */
  openDelete(id: number) {
    this.dialog.open(RemoveMyListComponent, {
      width: '320px',
      height: '160px',
      data: {
        userId: this.user.id,
        companyId: id,
      }
    });
  }

  /**
   * @summary Set page in pagination
   */
  onPagination() {
    this.pageEvent = new PageEvent;
    this.pageEvent.pageIndex = 0;
    this.pageEvent.length = this.companies.length;
  }

  /**
   * @summary Set pagination from value
   * @param pageEvent - event of page change
   */
  paginationFrom(pageEvent: any) {
    this.page = pageEvent.pageIndex + 1;
    return ((pageEvent.pageIndex === 0) ? pageEvent.pageIndex : (pageEvent.pageIndex) * pageEvent.pageSize);
  }

  /**
   * @summary Set pagination to value
   * @param pageEvent - event of page change
   */
  paginationTo(pageEvent: any) {
    this.page = pageEvent.pageIndex + 1;
    return this.paginationFrom(pageEvent) + this.pageSize;
  }

  /**
   * @summary Set number of company on page
   * @param value - size of page
   * @param pageEvent - event of page change
   */
  setPageSize(value: number, pageEvent: any) {
    this.pageSize = value;
    pageEvent.pageSize = value;
    this.openPagination = false;
  }

  /**
   * @summary Open dialog window of CompanyInfo component
   * @param company - company data
   */
  openCompany(company: Company) {
    this.dialog.open(CompanyInfoComponent, {
      width: '920px',
      height: '665px',
      data: {
        companies: [],
        company: company,
        i: null
      }
    });
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.myListSubscription);
  }
}
