import {Component, OnInit} from '@angular/core';
import {ToasterConfig} from 'angular2-toaster';
import {Router} from '@angular/router';

/**
 * @summary App component
 */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit {
  toasterconfig: ToasterConfig =
    new ToasterConfig({
      showCloseButton: false,
      tapToDismiss: true,
      timeout: 6000,
      animation: 'fade',
      newestOnTop: false,
      positionClass: 'toast-bottom-center'
    });

  /**
   * @summary App component constructor
   * @param router - Router service
   */
  constructor(private router: Router) {
  }

  /**
   * @summary Run when App component init
   */
  ngOnInit() {
    this.router.events.subscribe((evt) => {
      window.scrollTo(0, 0);
    });
  }
}
