export class Filter {
  country = [];
  includeCountry = [];
  includeCountryName = [];
  country_exclude = [];
  excludeCountryName = [];
  founded_in_after = null;
  founded_in_before = null;
  employees_min = 0;
  employees_max = 0;
  total_raised_min = 0;
  total_raised_max = 0;
  page = 0;
  regionName = null;
  tag_and = [];
  tag_or = [];
  includeTags = [];
  page_size = 25;
  name = null;
  format = null;
  ordering = null;
  last_updated_at_after = null;
  last_updated_at_before = null;
  includeInteresting = [];
}
