import {Component, Inject, OnDestroy, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {Subscription} from 'rxjs';
import {ToasterService} from 'angular2-toaster';

// service
import {CommonService} from '@shared/services/common.service';
import {InvestmentsService} from '@shared/services/investments.service';
import {CompaniesService} from '@shared/services/companies.service';
import {UserService} from '@shared/services/user.service';
import {MyListsService} from '@shared/services/my-lists.service';

// interfaces and models
import {InvestmentsList} from '@shared/common-interfaces/investmentsList-interface';
import {Investments} from '@shared/common-interfaces/investment-interface';
import {Company} from '@shared/common-interfaces/company-interface';
import { user } from '@app/shared/unit-test-services/mock-user.service';

/**
 * @summary CompanyInfo component
 */
@Component({
  selector: 'app-company-info',
  templateUrl: './company-info.component.html',
  styleUrls: ['./company-info.component.scss']
})

export class CompanyInfoComponent implements OnInit, OnDestroy {
  companiesSubscription: Subscription;
  user = this.userService.getCurrentUser();
  companies: Company[] = this.companyEL.companies;
  company: Company = this.companyEL.company;
  index: number = this.companyEL.i;
  open = 'overview';
  idEl = this.company.id;
  investmentList: Investments[] = [];
  disabled = false;
  userid = this.user.id;
  usercurrentradar = this.user;

  /**
   * @summary CompanyInfo component constructor
   * @param companiesService - companies service
   * @param commonService - common service
   * @param investmentsService - investments service
   * @param companyEL - data from pop-up
   * @param userService - user service
   * @param toaster - toaster service
   * @param myListsService - MyLists service
   */
  constructor(@Inject(MAT_DIALOG_DATA)
              public companyEL: { company: Company, i: number, companies: Company [], invest: boolean },
              private companiesService: CompaniesService,
              private myListsService: MyListsService,
              private commonService: CommonService,
              private investmentsService: InvestmentsService,
              private userService: UserService,
              private toaster: ToasterService) {
  }

  /**
   * @summary Run when CompanyInfo component init
   */
  ngOnInit() {
    this.getCompany();
    this.getInvestmentById(this.company.id);
    this.companiesService.setCompany(this.company);
  }

  /**
   * @summary Get company detail by id
   */
  getCompany() {
    this.companiesService.getCompanyById(this.company.id)
      .subscribe((company: Company) => {
        if (this.companyEL.invest + '' === 'true') {
          this.open = 'investments';
        }
        this.company = company;
      });
  }

  /**
   * @summary Get investments list by id company
   * @param id - id of company
   */
  getInvestmentById(id: number) {
    this.investmentsService.getInvestmentById(id)
      .subscribe((data: InvestmentsList) => {
        this.investmentList = data.results;
      });
  }

  /**
   * @summary Change company
   * @param next - flag for open next page
   */
  onNext(next: boolean) {
    this.index = next ? this.index + 1 : this.index - 1;
    this.company = this.companies[this.index];
    this.companiesService.setCompany(this.company);
    this.getInvestmentById(this.company.id);
    this.open = 'overview';
    this.idEl = this.company.id;
    this.deleteSimilar();
    this.getCompany();
  }

  /**
   * @summary Add company to MyList
   */
  addToMyList() {
    this.myListsService.addToMyList(this.user.id, {entity_id: this.company.id})
      .subscribe(res => {
        this.getCompany();
        this.toaster.pop('success', res.message);
      });
  }

  /**
   * @summary Delete company from MyList
   */
  deleteSimilar() {
    this.companiesService.setSimilar(null);
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.companiesSubscription);
    this.deleteSimilar();
  }
}
