import {Component, OnInit} from '@angular/core';

/**
 * @summary ProgressSpinner component
 */
@Component({
  selector: 'app-progress-spinner',
  templateUrl: './progress-spinner.component.html',
  styleUrls: ['./progress-spinner.component.scss']
})

export class ProgressSpinnerComponent implements OnInit {
  mode = 'indeterminate';
  value = 50;

  /**
   * @summary ProgressSpinner component constructor
   */
  constructor() {
  }

  /**
   * @summary Run when ProgressSpinner component init
   */
  ngOnInit() {
  }
}
