import {Component, Input, OnInit} from '@angular/core';
import {Category} from '@shared/common-interfaces/category-interface';
import {Region} from '@shared/common-interfaces/region-interface';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {CompaniesService} from '@shared/services/companies.service';
import {MatDatepickerInputEvent} from '@angular/material';
import {TypeData} from '@shared/common-interfaces/type-data-interface';
import {CommonService} from '@shared/services/common.service';

/**
 * @summary HeatMapFilter component
 */
@Component({
  selector: 'app-heat-map-filter',
  templateUrl: './heat-map-filter.component.html',
  styleUrls: ['./heat-map-filter.component.scss']
})
export class HeatMapFilterComponent implements OnInit {
  @Input('company') company: boolean;
  @Input('investment') investment: boolean;
  @Input('news') news: boolean;
  filter = new HeatmapFilter();
  toggle = false;
  typesCompanies: TypeData [] = [
    {name: 'Number companies', value: 'number_companies', id: 0},
    {name: 'Number employees', value: 'number_employees', id: 1}
  ];
  typesInvestments = [
    {name: 'Total raised', value: 'total_raised', id: 0},
    {name: 'Amount', value: 'amount', id: 1}
  ];

  typesNews = [
    {name: 'Number companies', value: 'number_companies', id: 0},
    {name: 'Number of news', value: 'number_news', id: 1}
  ];
  dataName: string;
  dataValue: string;

  columnList: Category[] = [];
  columnName: string;
  columnValue: string;

  rowList: Category[] = [];
  rowName: string;
  rowValue: string;

  regions: Region[];
  regionName: string;
  regionValue: string;

  dataToggle = false;
  columnToggle = false;
  rowToggle = false;
  regionToggle = false;

  /**
   * @summary HeatMapFilter component constructor
   * @param companiesService - Companies service
   * @param commonService - Common service
   */
  constructor(private companiesService: CompaniesService,
              private commonService: CommonService) {
  }

  /**
   * @summary Run when HeatMapFilter component init
   */
  ngOnInit() {
    this.getRegions();
    this.getCategory();
  }

  /**
   * @summary Set value of data filter
   * @param type - type data
   */
  setType(type: TypeData) {
    this.dataValue = type.name;
    this.filter.filter_type = type.value;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Set value of column category
   * @param category - category value
   */
  setColumn(category: string) {
    this.columnValue = category;
    this.filter.columns = category;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Set value of column category
   * @param category - category value
   */
  setRow(category: string) {
    this.rowValue = category;
    this.filter.rows = category;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Set value of region filter
   * @param region - region data
   */
  setRegion(region?: Region) {
    if (region) {
      this.regionValue = region.name;
      this.filter.region = region.id;
    } else {
      this.regionValue = 'All';
      this.filter.region = null;
    }
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Get region list from server
   */
  getRegions() {
    this.companiesService.getRegions()
      .subscribe((regions: Region[]) => {
        this.regions = regions;
      });
  }

  /**
   * @summary Get category list from server
   */
  getCategory() {
    this.companiesService.getCategory()
      .subscribe((category: { category: string } []) => {
        this.columnList = category;
        this.rowList = category;
      });
  }

  /**
   * @summary Set date for founded date heat map filter
   * @param event - date change event
   * @param flag - set after or before date
   */
  setFoundedDate(event: MatDatepickerInputEvent<Date>, flag: boolean) {
    if (event.value === null) {
      return;
    }
    const time = event.value;
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    if (flag) {
      this.filter.founded_in_after = date;
    }
    if (!flag) {
      this.filter.founded_in_before = date;
    }
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Set date for investment heatmap filter
   * @param event - date change event
   * @param flag - set after or before date
   */
  setInvestmentDate(event: MatDatepickerInputEvent<Date>, flag: boolean) {
    if (event.value === null) {
      return;
    }
    const time = event.value;
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    if (flag) {
      this.filter.investment_date_after = date;
    }
    if (!flag) {
      this.filter.investment_date_before = date;
    }
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Set date for investment heatmap filter
   * @param event - date change event
   * @param flag - set after or before date
   */
  setPublishedDate(event: MatDatepickerInputEvent<Date>, flag: boolean) {
    if (event.value === null) {
      return;
    }
    const time = event.value;
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    if (flag) {
      this.filter.publish_date_after = date;
    }
    if (!flag) {
      this.filter.publish_date_before = date;
    }
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteFoundedAfter() {
    this.filter.founded_in_after = null;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteFoundedBefore() {
    this.filter.founded_in_before = null;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deletePublishedAfter() {
    this.filter.publish_date_after = null;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deletePublishedBefore() {
    this.filter.publish_date_before = null;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteInvestmentAfter() {
    this.filter.investment_date_after = null;
    this.commonService.setHeatMapFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteInvestmentBefore() {
    this.filter.investment_date_before = null;
    this.commonService.setHeatMapFilter(this.filter);
  }
}
