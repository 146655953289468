import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Company} from '@shared/common-interfaces/company-interface';
import {CompaniesService} from '@shared/services/companies.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Subscription} from 'rxjs';
import {CommonService} from '@shared/services/common.service';
/**
 * @summary Similar component
 */
@Component({
  selector: 'app-similar',
  templateUrl: './similar.component.html',
  styleUrls: ['./similar.component.scss']
})

export class SimilarComponent implements OnInit, OnDestroy {
  @Input() id: number;
  similarSubscription: Subscription;
  companies: Company[];
  flag = false;
  sort: string;
  loading = false;

  /**
   * @summary Similar component constructor
   * @param route - ActivatedRoute service
   * @param companiesService - Companies service
   * @param commonService - Common service
   */
  constructor(private companiesService: CompaniesService,
              private route: ActivatedRoute,
              private commonService: CommonService) {
  }

  /**
   * @summary Run when Similar component init
   */
  ngOnInit() {
    this.getCompanyId();
  }

  /**
   * @summary Get id of company
   */
  getCompanyId() {
    this.route.params.subscribe(
      (params: Params) => {
        if (+params['id']) {
          this.id = +params['id'];
        }
        this.getSimilar();
      });
  }

  /**
   * @summary Get similar list of companies according id of company
   */
  getSimilar() {
    this.companiesService.getCurrentSimilar(this.id);
    this.similarSubscription = this.companiesService.similarSubject
      .subscribe((companies: Company []) => {
        if (companies) {
          this.loading = true;
        }
        this.companies = companies;
        }
      );
  }

  /**
   * @summary Sort value
   */
  onSort() {
    this.flag = !this.flag;
    this.sort = 'ratio';
  }

  /**
   * @summary Round value
   * @param count - number of value
   */
  math(count: number) {
    const result = + count * 100;
    return Math.ceil(result);
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.similarSubscription);
  }
}
