import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Company} from '@shared/common-interfaces/company-interface';
import {CompaniesService} from '@shared/services/companies.service';
import {Subscription} from 'rxjs';
import {CommonService} from '@shared/services/common.service';
import {ActivatedRoute, Params} from '@angular/router';
import {Evaluations} from '@shared/common-interfaces/evaluations-interface';

/**
 * @summary Overview component
 */
@Component({
  selector: 'app-overview',
  templateUrl: './overview.component.html',
  styleUrls: ['./overview.component.scss']
})

/**
 * @summary Overview component
 */
export class OverviewComponent implements OnInit, OnDestroy {
  @Input() company: Company;
  companySubscription: Subscription;
  id: number;
  novelty = [
    {value: 0},
    {value: 100}
  ];
  scalability = [
    {value: 0},
    {value: 100}
  ];
  color = {first: null, second: null};
  noveltyName = 'Novelty';
  scalabilityName = 'Scalability';

  /**
   * @summary Dashboard component constructor
   * @param route - ActivatedRoute service
   * @param companiesService - Companies service
   * @param commonService - Common service
   */
  constructor(private companiesService: CompaniesService,
              private route: ActivatedRoute,
              private commonService: CommonService) {
  }

  /**
   * @summary Run when Dashboard component init
   */
  ngOnInit() {
    this.getCompanyId();
  }

  /**
   * @summary Get id of company
   */
  getCompanyId() {
    this.route.params.subscribe(
      (params: Params) => {
        if (params['id']) {
          this.id = this.getParamsId(params['id']);
        }
        this.getCompany();
      }
    );
  }

  /**
   * @summary Check by investments tab open
   * @param params - query params
   */
  getParamsId(params: string) {
    return this.commonService.getParamsId(params);
  }

  /**
   * @summary Get company by id
   */
  getCompany() {
    this.companySubscription = this.companiesService.companySubject
      .subscribe((company: Company) => {
          this.company = company;
          this.id ? this.getEvaluation(this.id) : this.getEvaluation(this.company.id);
        }
      );
  }

  /**
   * @summary Get evaluation from service
   * @param id - id of company
   */
  getEvaluation(id: number) {
    this.companiesService.getEvaluations(id)
      .subscribe((evaluation: Evaluations) => {
        this.setEvaluation(evaluation);
      }, error => {
      });
  }

  /**
   * @summary Set property for pie chart
   * @param evaluation - evaluation data
   */
  setEvaluation(evaluation: { novelty: number, scalability: number }) {
    this.novelty = [
      {value: evaluation.novelty * 20},
      {value: 100 - evaluation.novelty * 20}
    ];
    this.scalability = [
      {value: evaluation.scalability * 20},
      {value: 100 - evaluation.scalability * 20}
    ];
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.companySubscription);
  }
}
