import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ToasterService} from 'angular2-toaster';
import {CommonService} from '@shared/services/common.service';
import {NewsService} from '@shared/services/news.service';
import {UserService} from '@shared/services/user.service';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {HeatMap} from '@shared/common-interfaces/heat-map-interface';

/**
 * @summary NewsHeatmap component
 */
@Component({
  selector: 'app-news-heatmap',
  templateUrl: './news-heatmap.component.html',
  styleUrls: ['./news-heatmap.component.scss']
})

export class NewsHeatmapComponent implements OnInit, OnDestroy {
  filterHeatMapSubscription: Subscription;
  closeSubscription: Subscription;
  filter = new HeatmapFilter();
  columnName: string;
  rowName: string;
  heatmap: any = [];
  publishedOpen = true;
  range = [
    {value_1: 0, value_2: 2},
    {value_1: 2, value_2: 5},
    {value_1: 5, value_2: 10},
    {value_1: 10, value_2: 20},
    {value_1: 20, value_2: 50},
    {value_1: 50, value_2: 100},
    {value_1: 100, value_2: 200},
    {value_1: 200, value_2: 201}
  ];
  colors = {
    2: '#FFF4CF',
    5: '#FFE6BB',
    10: '#FFCE97',
    20: '#FFBB6D',
    50: '#FFA559',
    100: '#FF9441',
    200: '#FF8326',
    201: '#FF6900'
  };
  legend = [2, 5, 10, 20, 50, 100, 200];
  loading = false;
  user = this.userService.getCurrentUser();

  /**
   * @summary NewsHeatmap component constructor
   * @param toaster - Toaster service
   * @param commonService - Common service
   * @param newsService - Common service
   * @param commonService - Common service
   * @param userService = User service
   */
  constructor(private toaster: ToasterService,
              private commonService: CommonService,
              private newsService: NewsService,
              private userService: UserService) {
  }

  /**
   * @summary Run when NewsHeatmap component init
   */
  ngOnInit() {
    this.setFilter();
    this.closeChange();
  }

  /**
   * @summary Get company list from server
   */
  setFilter() {
    this.filterHeatMapSubscription = this.commonService.filtersHeatMapSubject
      .subscribe((filter: HeatmapFilter) => {
          this.filter = filter;
          this.getHeatMap(this.filter);
        }
      );
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe(res => {
        this.getHeatMap(this.filter);
      });
  }

  /**
   * @summary Get data for heat map
   * @param filter = value of heatmap filter service
   */
  getHeatMap(filter: HeatmapFilter) {
    if (!this.user.allowed_radars.length && !this.user.is_admin) {
      return;
    }
    if (!this.checkFilter()) {
      return;
    }
    this.heatmap = [];
    this.loading = true;
    this.newsService.getHeatmap(filter)
      .subscribe((data: HeatMap) => {
        if (!data.result) {
          this.toaster.pop('success', data.message);
          this.loading = false;
          return;
        }
        this.heatmap = data.result;
        this.loading = false;
      });
  }

  /**
   * @summary Check filer value
   */
  checkFilter() {
    return !(!this.filter.filter_type || !this.filter.rows || !this.filter.columns);
  }

  /**
   * @summary Set color of heat map table cell
   * @param value = number of news
   */
  setColor(value: number) {
    const max = 201;
    for (const quantity of this.range) {
      if (value === 0) {
        return '#ffffff';
      }
      if (value >= quantity.value_1 && value < quantity.value_2 || value >= max) {
        return this.colors[value >= max ? max : quantity.value_2];
      }
    }
  }

  /**
   * @summary Save news heat map to png
   * @param elem = dom element
   */
  savePng(elem: any) {
    this.commonService.savePng(elem);
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.setHeatMapFilter(new HeatmapFilter());
    this.commonService.checkSubscription(this.filterHeatMapSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }
}
