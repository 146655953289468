import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {CompaniesService} from '../../services/companies.service';
import {Country} from '../../common-interfaces/country-interface';
import {Region} from '../../common-interfaces/region-interface';
import {FilterList} from '../../common-interfaces/filter-interface';

/**
 * @summary LocationFilter component
 */
@Component({
  selector: 'app-location-filter',
  templateUrl: './location-filter.component.html',
  styleUrls: ['./location-filter.component.scss']
})

export class LocationFilterComponent implements OnInit {
  @Output() openEl = new EventEmitter<boolean>();
  @Output() changeFilter = new EventEmitter<FilterList>();
  @Input() filter: FilterList;
  regions: Region[];
  region: Region;
  countryList: Country[];
  filterList: FilterList = {
    region: {
      id: null,
      name: null,
      include: false,
      countries: []
    },
    include: [],
    exclude: []
  };
  locationValue: string;

  /**
   * @summary LocationFilter component constructor
   */
  constructor(private companiesService: CompaniesService) {
  }

  /**
   * @summary Run when LocationFilter component init
   */
  ngOnInit() {
    this.getCountryList();
  }

  /**
   * @summary Get country list from server
   */
  getCountryList() {
    this.companiesService.getCountryList()
      .subscribe((countryList: any) => {
        this.countryList = countryList;
        this.getRegions();
      });
  }

  /**
   * @summary Get region list from server
   */
  getRegions() {
    this.companiesService.getRegions()
      .subscribe((regions: Region []) => {
        this.regions = regions;
        this.setFilterList(this.filter);
      });
  }

  /**
   * @summary Set value of location filter
   */
  setFilterList(filter: FilterList) {
    for (const regionEL of this.regions) {
      if (regionEL.name === filter.region.name) {
        regionEL.include = true;
        this.filterList.region = regionEL;
      }
    }
    for (const country of this.countryList) {
      for (const countryEl of filter.include) {
        if (country.country_code === countryEl ) {
          country.select = true;
          country.include = true;
          this.filterList.include.push(country);
        }
      }
      for (const countryEl of filter.exclude) {
        if (country.country_code === countryEl ) {
          country.select = true;
          country.include = false;
          this.filterList.exclude.push(country);
        }
      }
    }
  }

  /**
   * @summary Set region value for filter
   */
  setRegion(region: Region) {
    for (const regionEl of this.regions) {
      regionEl.include = false;
    }
    region.include = true;
    this.filterList.region = region;
  }

  /**
   * @summary Delete region value from filter
   */
  onDeleteRegion(region) {
    region.select = false;
    region.include = false;
    this.filterList.region = {id: null, name: null, include: false, countries: []};
  }

  /**
   * @summary Set country fo filter
   */
  setCountry(country: Country) {
    if (country.include) {
      return;
    }
    country.select = true;
    country.include = true;
    this.filterList.include.push(country);
  }

  /**
   * @summary Delete country value from filter
   */
  onDeleteCountry(country: Country) {
    if (country.include) {
      for (let i = 0; i < this.filterList.include.length; i++) {
        if (this.filterList.include[i].id === country.id) {
          this.filterList.include.splice(i, 1);
        }
      }
    } else {
      for (let i = 0; i < this.filterList.exclude.length; i++) {
        if (this.filterList.exclude[i].id === country.id) {
          this.filterList.exclude.splice(i, 1);
        }
      }
    }
    country.select = false;
    country.include = false;
  }

  /**
   * @summary Add country to include country filter list
   */
  includeCountry(country: Country) {
    country.include = true;
    for (let i = 0; i < this.filterList.exclude.length; i++) {
      if (this.filterList.exclude[i].id === country.id) {
        this.filterList.exclude.splice(i, 1);
        this.filterList.include.push(country);
      }
    }
  }

  /**
   * @summary Add country to exclude country filter list
   */
  excludeCountry(country: Country) {
    country.include = false;
    for (let i = 0; i < this.filterList.include.length; i++) {
      if (this.filterList.include[i].id === country.id) {
        this.filterList.include.splice(i, 1);
        this.filterList.exclude.push(country);
      }
    }
  }

  /**
   * @summary Add location filter
   */
  onAddFilter() {
    this.changeFilter.emit(this.filterList);
    this.openEl.emit(false);
  }

}
