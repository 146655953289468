import {Component, OnDestroy, OnInit} from '@angular/core';
import {CommonService} from '@shared/services/common.service';
import {Subscription} from 'rxjs';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {CompaniesService} from '@shared/services/companies.service';
import {ToasterService} from 'angular2-toaster';
import {HeatMap} from '@shared/common-interfaces/heat-map-interface';
import {InvestmentsService} from '@shared/services/investments.service';
import {UserService} from '@shared/services/user.service';

/**
 * @summary InvestmentsHeatmap component
 */
@Component({
  selector: 'app-investments-heatmap',
  templateUrl: './investments-heatmap.component.html',
  styleUrls: ['./investments-heatmap.component.scss']
})

export class InvestmentsHeatmapComponent implements OnInit, OnDestroy {
  filterHeatMapSubscription: Subscription;
  closeSubscription: Subscription;
  investmentOpen = true;
  columnName: string;
  rowName: string;
  filter = new HeatmapFilter();
  heatmap: any = [];
  range = [
    {value_1: 0, value_2: 10000},
    {value_1: 10000, value_2: 100000},
    {value_1: 100000, value_2: 500000},
    {value_1: 500000, value_2: 1000000},
    {value_1: 1000000, value_2: 2000000},
    {value_1: 2000000, value_2: 5000000},
    {value_1: 5000000, value_2: 10000000},
    {value_1: 10000000, value_2: 10000001}
  ];
  colors = {
    10000: '#FFF4CF',
    100000: '#FFE6BB',
    500000: '#FFCE97',
    1000000: '#FFBB6D',
    2000000: '#FFA559',
    5000000: '#FF9441',
    10000000: '#FF8326',
    10000001: '#FF6900'
  };
  legend = [10000, 100000, 500000, 1000000, 2000000, 5000000, 10000000];
  loading = false;
  user = this.userService.getCurrentUser();

  /**
   * @summary InvestmentsHeatmap component constructor
   * @param toaster - Toaster service
   * @param commonService - Common service
   * @param companiesService - Companies service
   * @param investmentsService - Investment service
   * @param userService = User service
   */
  constructor(private companiesService: CompaniesService,
              private toaster: ToasterService,
              private commonService: CommonService,
              private investmentsService: InvestmentsService,
              private userService: UserService) {
  }

  /**
   * @summary Run when CompaniesHeatmap component init
   */
  ngOnInit() {
    this.setFilter();
    this.closeChange();
  }

  /**
   * @summary Get company list from server
   */
  setFilter() {
    this.filterHeatMapSubscription = this.commonService.filtersHeatMapSubject
      .subscribe((filter: HeatmapFilter) => {
          this.filter = filter;
          this.getHeatMap(this.filter);
        }
      );
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe(res => {
        this.getHeatMap(this.filter);
      });
  }

  /**
   * @summary Get data for heat map
   * @param filter = filter data
   */
  getHeatMap(filter: HeatmapFilter) {
    if (!this.user.allowed_radars.length && !this.user.is_admin) {
      return;
    }
    if (!this.checkFilter()) {
      return;
    }
    this.heatmap = [];
    this.loading = true;
    this.investmentsService.getHeatmap(filter)
      .subscribe((data: HeatMap) => {
        if (!data.result) {
          this.toaster.pop('success', data.message);
          this.loading = false;
          return;
        }
        this.heatmap = data.result;
        this.loading = false;
      });
  }

  /**
   * @summary Check filer value
   */
  checkFilter() {
    return !(!this.filter.filter_type || !this.filter.rows || !this.filter.columns);
  }

  /**
   * @summary Set color of heat map table cell
   * @param value = number of investments
   */
  setColor(value: number) {
    const max = 10000001;
    for (const quantity of this.range) {
      if (value === 0) {
        return '#ffffff';
      }
      if (value >= quantity.value_1 && value < quantity.value_2 || value >= max) {
        return this.colors[value >= max ? max : quantity.value_2];
      }
    }
  }

  /**
   * @summary Save heat map to png
   * @param elem = DOM elem
   */
  savePng(elem: any) {
    this.commonService.savePng(elem);
  }

  /**
   * @summary Edit value of investments
   * @param value = number
   */
  editNumber(value: number) {
    return Math.round(value / 1000000 * 100) / 100;
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.setHeatMapFilter(new HeatmapFilter());
    this.commonService.checkSubscription(this.filterHeatMapSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }
}
