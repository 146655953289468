import {Component, Input, OnInit} from '@angular/core';
import {Investments} from '@shared/common-interfaces/investment-interface';
import {Company} from '@shared/common-interfaces/company-interface';

/**
 * @summary CompanyInvestments component
 */
@Component({
  selector: 'app-company-investments',
  templateUrl: './company-investments.component.html',
  styleUrls: ['./company-investments.component.scss']
})

export class CompanyInvestmentsComponent implements OnInit {
  @Input() companyEl: Company;
  @Input() investments: Investments[];

  /**
   * @summary CompanyInvestments component constructor
   */
  constructor() {
  }

  /**
   * @summary Run when CompanyInfo component init
   */
  ngOnInit() {
  }
}
