import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDatepickerInputEvent, MatDialog} from '@angular/material';
import {ActivatedRoute, Params} from '@angular/router';

// Services
import {UserService} from '@shared/services/user.service';
import {NewsService} from '@shared/services/news.service';
import {CompaniesService} from '@shared/services/companies.service';
import {CommonService} from '@shared/services/common.service';
import {InvestmentsService} from '@shared/services/investments.service';

// Interface and models
import {NewsFilter} from '@shared/models/news-filter.model';
import {Source} from '@shared/common-interfaces/sources-interface';
import {Name, NameList} from '@shared/common-interfaces/news-list-interface';
import {Tags} from '@shared/common-interfaces/tags-interface';
import {Types} from '@shared/common-interfaces/types-interface';
import {Region} from '@shared/common-interfaces/region-interface';
import {Tag} from '@shared/common-interfaces/tag-interface';
import {Type} from '@shared/common-interfaces/type-interface';

/**
 * @summary FilterNews component
 */
@Component({
  selector: 'app-filter-news',
  templateUrl: './filter-news.component.html',
  styleUrls: ['./filter-news.component.scss']
})

export class FilterNewsComponent implements OnInit, OnDestroy {
  closeSubscription: Subscription;
  filter = new NewsFilter();
  pageIndex = 0;
  toggleFilter = false;

  openName = false;
  names: Name [] = [];
  companyName: string;
  nameValue: string;

  openRegion = false;
  regions: Region [];
  regionName: string;
  regionsId: number [] = [];
  includeRegions: string [] = [];

  openSource = false;
  sources: Source [];
  sourceName: string;
  sourcesId: number [] = [];
  includeSources: string [] = [];

  openTag = false;
  tagList: Tag[];
  tagName: string;
  tagsId: number[] = [];
  includeTags: string[] = [];
  radioButton = 'and';

  openType = false;
  types: Type[] = [];
  typeName: string;
  typesId: number[] = [];
  includeTypes: string[] = [];

  /**
   * @summary FilterInvestments component constructor
   * @param companiesService - Companies service
   * @param dialog - MatDialog service
   * @param commonService - Common service
   * @param investmentsService - Investments service
   * @param userService - User service
   * @param route - ActivatedRoute service
   * @param newsService - News service
   */
  constructor(private dialog: MatDialog,
              private companiesService: CompaniesService,
              private commonService: CommonService,
              private investmentsService: InvestmentsService,
              private userService: UserService,
              private route: ActivatedRoute,
              private newsService: NewsService) {
  }

  /**
   * @summary Run when FilterInvestments component init
   */
  ngOnInit() {
    this.checkQueryParams();
    this.closeChange();
    this.getRegions();
    this.getSources();
    this.getTagList();
    this.getTypes();
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      this.filter = Object.assign(this.filter, params);
      if (params.page) {
        this.showFilterParams();
      }
      this.newsService.setFilter(this.filter);
    });
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe(res => {
        this.getTagList();
      });
  }

  /**
   * @summary Get region list from server
   */
  getRegions() {
    this.companiesService.getRegions()
      .subscribe((regions: Region[]) => {
        this.regions = regions;
      });
  }

  /**
   * @summary Get sources list
   */
  getSources() {
    this.newsService.getSources()
      .subscribe(
        (sources: Source []) => {
          this.sources = sources;
        });
  }

  /**
   * @summary Get tag list
   */
  getTagList() {
    this.commonService.getTagList()
      .subscribe(
        (tags: Tags) => {
          this.tagList = tags.results;
        });
  }

  /**
   * @summary Get type list
   */
  getTypes() {
    this.newsService.getTypes()
      .subscribe((types: Types) => {
        this.types = types.results;
      });
  }

  /**
   * @summary Set value of company name filter
   * @param name - value name
   */
  setName(name: string) {
    this.nameValue = name;
    this.filter.entity__name = name;
    this.newsService.setFilter(this.filter);
    this.openName = false;
  }

  /**
   * @summary Set value of regions filter
   * @param region - region value
   */
  setRegion(region?: Region) {
    this.includeRegions.push(region.name);
    this.regionsId.push(region.id);
    this.openRegion = false;
    this.regionName = null;
    this.regionFilter();
  }

  /**
   * @summary Set value of source filter
   * @param source - source value
   */
  setSource(source: Source) {
    this.includeSources.push(source.name);
    this.sourcesId.push(source.id);
    this.openSource = false;
    this.sourceName = null;
    this.sourceFilter();
  }

  /**
   * @summary Set value of tags filter
   * @param tag - tag value
   */
  setTag(tag: Tag) {
    this.includeTags.push(tag.name);
    this.tagsId.push(tag.id);
    this.openTag = false;
    this.tagName = null;
    this.tagFilter();
  }

  /**
   * @summary Set value of types filter
   * @param type - type value
   */
  setType(type: Type) {
    this.includeTypes.push(type.name);
    this.typesId.push(type.id);
    this.openType = false;
    this.typeName = null;
    this.typeFilter();
  }

  /**
   * @summary Delete region from filter
   * @param index - index of region
   */
  deleteRegion(index: number) {
    this.includeRegions.splice(index, 1);
    this.regionsId.splice(index, 1);
    this.regionFilter();
  }

  /**
   * @summary Delete source from filter
   * @param index - index of source
   */
  deleteSource(index: number) {
    this.includeSources.splice(index, 1);
    this.sourcesId.splice(index, 1);
    this.sourceFilter();
  }

  /**
   * @summary Delete tag from filter
   * @param index - index of tag
   */
  deleteTag(index: number) {
    this.includeTags.splice(index, 1);
    this.tagsId.splice(index, 1);
    this.tagFilter();
  }

  /**
   * @summary Delete type from filter
   * @param index - index of type
   */
  deleteType(index: number) {
    this.includeTypes.splice(index, 1);
    this.typesId.splice(index, 1);
    this.typeFilter();
  }

  /**
   * @summary Clear regions filter
   */
  skipRegions() {
    this.includeRegions = [];
    this.regionsId = [];
    this.regionFilter();
  }

  /**
   * @summary Clear sources filter
   */
  skipSources() {
    this.includeSources = [];
    this.sourcesId = [];
    this.sourceFilter();
  }

  /**
   * @summary Clear tags filter
   */
  skipTags() {
    this.includeTags = [];
    this.tagsId = [];
    this.tagFilter();
  }

  /**
   * @summary Clear types filter
   */
  skipTypes() {
    this.includeTypes = [];
    this.typesId = [];
    this.typeFilter();
  }

  /**
   * @summary Filter company-news by company name
   */
  nameFilter() {
    this.nameValue = null;
    this.newsService.getCompanyNames(this.companyName)
      .subscribe((names: NameList) => {
        if (!names.results) {
          this.names = [];
          return;
        }
        this.openName = true;
        this.names = names.results;
      });
  }

  /**
   * @summary Filter company-news by regions
   */
  regionFilter() {
    this.filter.region = this.regionsId;
    this.filter.includeRegions = this.includeRegions;
    this.newsService.setFilter(this.filter);
  }


  /**
   * @summary Filter company-news by sources
   */
  sourceFilter() {
    this.filter.media = this.sourcesId;
    this.filter.includeMedia = this.includeSources;
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Filter company-news by tags include 'and' or 'or'
   */
  tagFilter() {
    if (!this.tagsId.length) {
      this.tagsId = [];
    }
    if (this.radioButton === 'and') {
      this.filter.tag_and = this.tagsId;
      this.filter.tag_or = null;
    }
    if (this.radioButton === 'or') {
      this.filter.tag_and = null;
      this.filter.tag_or = this.tagsId;
    }
    this.filter.includeTags = this.includeTags;
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Filter company-news by types
   */
  typeFilter() {
    this.filter.type = this.typesId;
    this.filter.includeTypes = this.includeTypes;
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Show filter params in filter components
   */
  showFilterParams() {
    this.companyName = this.filter.entity__name ? this.filter.entity__name : '';
    this.includeRegions = this.filter.includeRegions ? this.filter.includeRegions : [];
    this.includeSources = this.filter.includeMedia ? this.filter.includeMedia : [];
    this.includeTags = this.filter.includeTags ? this.filter.includeTags : [];
    if (this.filter.tag_and) {
      this.tagsId = this.filter.tag_and;
    }
    if (this.filter.tag_or) {
      this.tagsId = this.filter.tag_or;
      this.radioButton = 'or';
    }
    this.includeTypes = this.filter.includeTypes ? this.filter.includeTypes : [];
  }

  /**
   * @summary Get date interval of founded company
   * @param event - event date
   * @param flag - set after or before date
   */
  getDate(event: MatDatepickerInputEvent<Date>, flag: boolean) {
    if (event.value === null) {
      return;
    }
    const time = event.value;
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    if (flag) {
      this.filter.publish_date_after = date;
    }
    if (!flag) {
      this.filter.publish_date_before = date;
    }
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteAfter() {
    this.filter.publish_date_after = null;
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteBefore() {
    this.filter.publish_date_before = null;
    this.newsService.setFilter(this.filter);
  }

  /**
   * @summary Change tag filter settings
   * @param event - event change radio value
   */
  radioChange(event: any) {
    if (!this.tagsId.length) {
      this.radioButton = event.value;
      return;
    }
    this.radioButton = event.value;
    this.tagFilter();
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.closeSubscription);
  }

}
