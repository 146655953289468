import {Directive, ElementRef, EventEmitter, Input, OnChanges, Output} from '@angular/core';
import * as d3 from 'd3';

@Directive({
  selector: '[appPieChart]'
})

export class PieChartDirective implements OnChanges {
  @Input('data') data: any;
  @Input('type') type: string;
  @Input('noAgent') noAgent: boolean;
  @Input('degree') degree: boolean;
  @Input('title') title: string;
  @Output() changeColor = new EventEmitter<any>();
  svg: any;
  g: any;
  text: any;
  count: any;

  constructor(private el: ElementRef) {
  }

  ngOnChanges(changes) {
    if (this.data) {
      this.createChart(this.data);
    }
  }

  createChart(data: any) {
    const w = this.el.nativeElement.clientWidth,
      h = this.el.nativeElement.clientWidth,
      r = Math.min(w, h) / 2,
      color = ['#fff', '#FFE6BB'];

    const pie = (<any>d3).pie().value(function (d: any) {
      return d.value;
    }).sort(null);
    const arc: any = (<any>d3).arc();
    const outerArc = (<any>d3).arc()
      .innerRadius(r / 1.55)
      .outerRadius(r / 1.55);
    arc.outerRadius(r * 0.84).innerRadius(r / 1.8);

    if (!this.svg) {
      this.svg = (<any>d3).select(this.el.nativeElement)
        .append('svg');

      this.g = this.svg
        .attr('width', '100%')
        .attr('height', '100%')
        .attr('viewBox', '0 0 ' + Math.min(w, h) + ' ' + Math.min(w, h))
        .attr('preserveAspectRatio', 'xMinYMin')
        .append('g')
        .attr('transform', 'translate(' + Math.min(w, h) / 2 + ',' + Math.min(w, h) / 2 + ')');
    }

    const arcs = this.g.selectAll('path')
      .data(pie(data));

    arcs.enter()
      .append('path')
      .attr('class', function (d: any, i: any) {
        return 'slice-' + i;
      })
      .attr('fill', function (d: any, i: any) {
        return color[i];
      })
      .attr('d', arc)
      .each(function (d: any) {
        return this._current = d;
      });

    arcs.transition().duration(1000).attrTween('d', arcTween);
    arcs.exit().remove();

    if (!this.text) {
      this.text = this.g.append('text')
        .attr('dy', '.8em')
        .style('text-anchor', 'middle')
        .attr('class', 'inside')
        .attr('font-family', 'Mukta', 'sans-serif')
        .attr('font-size', '24px')
        .attr('font-weight', '700')
        .attr('fill', '#808080');
    }
    if (!this.count) {
      this.count = this.g.append('text')
        .attr('dy', '-.75em')
        .style('text-anchor', 'middle')
        .attr('class', 'inside')
        .attr('font-family', 'Mukta', 'sans-serif')
        .attr('font-size', '14px')
        .attr('font-weight', '400')
        .attr('fill', '#808080')
        .text(this.title);
    }

    const self = this;

    function arcTween(a: any) {
      const i = (<any>d3).interpolate(this._current, a);
      this._current = i(0);
      return (t: any) => {
        let d = data[0].value;
        d = i(d);
        if (d.index === 0) {
          d.endAngle = i(t);
          const val = Math.round(d.endAngle.value);
          if (val <= 20) {
            self.changeColor.emit('low');
          } else if (val > 20 && val <= 40) {
            self.changeColor.emit('middle');
          } else if (val > 40 && val <= 60) {
            self.changeColor.emit('above-average');
          } else if (val > 60 && val <= 80) {
            self.changeColor.emit('high');
          } else {
            self.changeColor.emit('full');
          }
          const symbol = self.degree ? '°C' : '%';
          self.text.text(self.noAgent ? '―%' : val + symbol);
        }
        return arc(i(t));
      };
    }
  }
}
