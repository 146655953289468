import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {finalize} from 'rxjs/operators';
import {MatDialog} from '@angular/material';
import {UserService} from '@shared/services/user.service';
import {Router} from '@angular/router';
import {RecoveryComponent} from '@app/auth/recovery/recovery.component';

/**
 * @summary Sign in component
 */
@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})

export class SignInComponent implements OnInit {
  @Output() close = new EventEmitter<boolean>();
  signInForm: FormGroup;
  disabled: boolean;

  /**
   * @summary Sign in component constructor
   * @param dialog - MatDialog service
   * @param router - Router service
   * @param userService - User service
   */
  constructor(private userService: UserService,
              private router: Router,
              private dialog: MatDialog) {

  }

  /**
   * @summary Run when sign in component init
   */
  ngOnInit() {
    this.initForm();
  }

  /**
   * @summary Init form
   */
  initForm() {
    this.signInForm = new FormGroup({
      'email': new FormControl(null, [
        Validators.required,
        Validators.email
      ]),
      'password': new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ])
    });
  }

  /**
   * @summary Login user
   */
  onSingIn() {
    this.disabled = !this.disabled;
    this.userService.signIn(this.signInForm.value)
      .pipe(finalize( () => this.disabled = !this.disabled))
      .subscribe((data: {key: string}) => {
        localStorage.setItem('token', data.key);
        this.router.navigate(['/companies']);
      });
  }

  /**
   * @summary Open dialog window of Recovery component
   */
  recoveryPassword() {
    this.dialog.open(RecoveryComponent, {
      width: '600px',
      height: '400px',
      data: {
        send: false
      }
    });
  }

  /**
   * @summary Open sign up page
   */
  openSingUp() {
    this.close.emit(false);
  }
}
