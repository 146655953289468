import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material';
import {News} from '@shared/common-interfaces/news-list-interface';
import {NewsService} from '@shared/services/news.service';

/**
 * @summary NewsDetail component
 */
@Component({
  selector: 'app-news-detail',
  templateUrl: './news-detail.component.html',
  styleUrls: ['./news-detail.component.scss']
})

export class NewsDetailComponent implements OnInit {
  news: News = this.newsEl.news;
  index: number = this.newsEl.i;
  newsList: News [] = this.newsEl.newsList;

  /**
   * @summary CompanyInfo component constructor
   * @param newsEl - data for pop-up
   * @param newsService - News service
   */
  constructor(
    @Inject(MAT_DIALOG_DATA) public newsEl: {
      news: News,
      i: number,
      newsList: News []
    },
    private newsService: NewsService) {

  }

  /**
   * @summary Run when NewsDetail component init
   */
  ngOnInit() {
    this.getNewsById();
  }

  /**
   * @summary Get company-news detail by id
   */
  getNewsById() {
    this.newsService.getNewsById(this.news.id)
      .subscribe((data: News) => {
        this.news = data;
      });
  }

  /**
   * @summary Change company
   * @param next - flag for open next page
   */
  onNext(next: boolean) {
    this.index = next ? this.index + 1 : this.index - 1;
    this.news = this.newsList[this.index];
    this.getNewsById();
  }

}
