export class NewsFilter {
  entity__name = null;
  region = null;
  includeRegions = null;
  media = null;
  includeMedia = null;
  tag_and = null;
  tag_or = null;
  includeTags = null;
  type = null;
  includeTypes = null;
  publish_date_after = null;
  publish_date_before = null;
  page = 0;
  format = null;
  page_size = 25;
  ordering = null;
}
