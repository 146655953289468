import {Filter} from '@shared/models/filter.model';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {MatDialog, PageEvent} from '@angular/material';
import 'hammerjs/hammer';
import {Subscription} from 'rxjs';
import {mapParams} from '@app/dashboard/params-map';
import * as FileSaver from 'file-saver';
import {ActivatedRoute, Params} from '@angular/router';
import {ToasterService} from 'angular2-toaster';

// Components
import {CompanyInfoComponent} from '@app/dashboard/companies/company-info/company-info.component';

// Services
import {CompaniesService} from '@shared/services/companies.service';
import {CommonService} from '@shared/services/common.service';
import {UserService} from '@shared/services/user.service';
import {MySearchService} from '@shared/services/my-search-service';

// Interface and models
import {Entities} from '@shared/common-interfaces/entities-interface';
import {Countries} from '@shared/common-interfaces/countries-interface';
import {Location} from '@shared/common-interfaces/location-interface';
import {Company} from '@shared/common-interfaces/company-interface';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';
import {Th} from '@shared/common-interfaces/th-list-interface';

/**
 * @summary CompaniesSearch component
 */
@Component({
  selector: 'app-companies-search',
  templateUrl: './companies-search.component.html',
  styleUrls: ['./companies-search.component.scss']
})

export class CompaniesSearchComponent implements OnInit, OnDestroy {
  filterSubscription: Subscription;
  closeSubscription: Subscription;
  filter: Filter = null;
  companies: Company [] = [];
  thList: Th [] = [
    {name: 'name', sort: 'name', flag: false},
    {name: 'url', sort: 'url', flag: false},
    {name: 'country', sort: 'country', flag: false},
    {name: 'city', sort: 'city', flag: false},
    {name: 'founded in', sort: 'founded_in', flag: false},
    {name: 'employees', sort: 'employees', flag: false},
    {name: 'total raised, $', sort: 'total_raised', flag: false}
  ];
  pageEvent: PageEvent;
  length: number;
  pageSize = 25;
  pageSizeOptions = [10, 25, 50];
  pageIndex = 0;

  region: string;
  paramsMap = mapParams();
  lat = 50;
  lng = 50;
  openPageSize = false;
  locations = {};
  countriesLocation: Location [] = [];
  search: MySearch;
  active: number;
  user = this.userService.getCurrentUser();
  lastOpen: any;

  /**
   * @summary CompaniesSearch component constructor
   * @param dialog - MatDialog service
   * @param userService - User service
   * @param companiesService - Companies service
   * @param commonService - Common service
   * @param toaster - Toaster service
   * @param mySearchService - MySearch service
   * @param route - ActivatedRoute service
   */
  constructor(private dialog: MatDialog,
              private companiesService: CompaniesService,
              private commonService: CommonService,
              private userService: UserService,
              private mySearchService: MySearchService,
              private toaster: ToasterService,
              private route: ActivatedRoute) {
  }

  /**
   * @summary Run when CompaniesSearch component init
   */
  ngOnInit() {
    this.setFilter();
    this.getLocations();
    this.closeChange();
    this.checkQueryParams();
  }

  /**
   * @summary Get company list from server
   */
  setFilter() {
    this.filterSubscription = this.companiesService.filtersSubject
      .subscribe((filter: Filter) => {
          this.filter = filter;
          this.checkPage();
          this.getCompanies();
        }
      );
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe((res: boolean) => {
        this.getCompanies();
      });
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.id) {
        this.companiesService.getCompanyById(+params.id)
          .subscribe((company: Company) => {
            this.openCompanyById(company, params.invest);
          });
      }
    });
  }

  /**
   * @summary Open dialog window of Company detail
   * @param company - invest data
   * @param invest - invest tab
   */
  openCompanyById(company: Company, invest: boolean) {
    this.dialog.open(CompanyInfoComponent, {
      width: '920px',
      height: '665px',
      data: {
        companies: [],
        company: company,
        i: null,
        invest: invest
      }
    });
  }

  /**
   * @summary Get company list from server
   */
  getCompanies() {
    this.lastOpen = null;
    if (!this.user.allowed_radars.length && !this.user.is_admin) {
      return;
    }
    this.companiesService.getCompanies(this.filter)
      .subscribe((data: Entities) => {
          this.filter.page = this.pageIndex + 1;
          this.companies = data.results;
          this.length = data.count;
          this.commonService.setAmount(data.max_total_raised, + data.max_employees, 0);
          this.countriesLocation = this.getCoordinates(this.locations, data.countries);
          this.companiesService.setCompanies(this.companies);
        }
      );
  }

  /**
   * @summary Check number of page on zero
   */
  checkPage() {
    if (this.filter.page > 0) {
      this.filter.page = 0;
    }
    this.pageIndex = 0;
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param index - invest of company
   */
  openCompany(index: number) {
    this.dialog.open(CompanyInfoComponent, {
      width: '920px',
      height: '665px',
      data: {
        companies: this.companies,
        company: this.companies[index],
        i: index
      }
    });
  }


  /**
   * @summary Change page of company list
   * @param event - event of pagination
   */
  changePage(event: any) {
    this.pageEvent = event;
    const page = event.pageIndex;
    this.filter.page = event.pageIndex + 1;
    this.pageIndex = page;
    this.getCompanies();
  }

  /**
   * @summary Set number of company on page
   * @param value - size of page
   */
  setPageSize(value: number) {
    this.filter.page_size = value;
    this.pageSize = value;
    this.checkPage();
    this.getCompanies();
    this.openPageSize = false;
  }

  /**
   * @summary Set value name for search filter
   */
  searchName() {
    this.checkPage();
    this.getCompanies();
  }

  /**
   * @summary Save company list in csv file
   */
  saveCsv() {
    this.filter.format = 'csv';
    this.companiesService.getFile(this.filter)
      .subscribe((res: any) => {
        FileSaver.saveAs(res.body, this.commonService.getFileName(res));
      });
  }

  /**
   * @summary Get locations for filter
   */
  getLocations() {
    this.companiesService.getLocations()
      .subscribe((data: any) => {
        this.locations = data;
      });
  }

  /**
   * @summary Get country on google maps
   * @param locations - locations list
   * @param countries - countries list
   */
  getCoordinates(locations: {}, countries: Countries[]) {
    if (!countries) {
      return;
    }
    const result = [];
    for (const country of countries) {
      if (country.country === 'UK') {
        country.country = 'GB';
      }
      if (country.country === '' || country.country === '0') {
        country.country = 'US';
      }
      const elem = locations[country.country.toLowerCase()];
      if (!elem) {
        continue;
      }
      elem.count = country.count;
      elem.code = country.country.toLocaleLowerCase();
      elem.url = this.getMarker(country.count);
      result.push(locations[country.country.toLowerCase()]);
    }
    return result;
  }

  /**
   * @summary Get marker according count
   * @param count - value of countries
   */
  getMarker(count: number) {
    let value = 0;
    if (count <= 5) {
      value = 5;
    } else if (count <= 5 && count < 10) {
      value = 10;
    } else if (count <= 10 && count < 25) {
      value = 25;
    } else {
      value = 50;
    }
    return `/assets/imgs/icons/location-${value}.svg`;
  }

  /**
   * @summary Open info window of agm marker
   * @param infoWindow - modal window
   * @param gm - value of open window
   */
  showCountry(infoWindow: any, gm: any) {
    if (this.lastOpen != null) {
      gm.lastOpen.close();
    }
    gm.lastOpen = infoWindow;
    infoWindow.open();
    this.lastOpen = gm.lastOpen;
  }

  /**
   * @summary Save company search list
   */
  onSave() {
    this.search = {
      name: this.commonService.createDate('comp_search'),
      text: JSON.stringify(this.filter),
      user: this.user.id
    };
    this.mySearchService.saveMySearch(this.search)
      .subscribe(res => {
        this.toaster.pop('success', 'Search list added');
      });
  }

  /**
   * @summary Sort table column by min and max
   * @param th - page size
   * @param i - index
   */
  sort(th: Th, i: number) {
    this.active = i;
    for (const elem of this.thList) {
      if (elem.name !== th.name) {
        elem.flag = false;
      }
    }
    let sort = '';
    th.flag = !th.flag;
    if (!th.flag) {
      sort = '-';
    }
    this.filter.ordering = sort + th.sort;
    this.getCompanies();
  }

  /**
   * @summary Scroll to elem
   * @param el - DOM element
   */
  scrollTo(el) {
    const elementRect = el.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - (window.innerHeight / 2);
    window.scroll({
      top: middle,
      left: 0,
      behavior: 'smooth'
    });
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.filterSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }
}
