import {ToasterModule, ToasterService} from 'angular2-toaster';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {BrowserModule} from '@angular/platform-browser';
import {NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';

// Modules
import {SharedModule} from '@shared/modules/shared.module';
import {AppRoutingModule} from '@app/app-routing.module';
import {AuthModule} from '@app/auth/auth.module';
import {DashboardModule} from '@app/dashboard/dashboard.module';

// Service
import {ApiService} from '@shared/services/api.service';
import {UserService} from '@shared/services/user.service';
import {CommonService} from '@shared/services/common.service';
import {CompaniesService} from '@shared/services/companies.service';
import {InvestmentsService} from '@shared/services/investments.service';
import {MyListsService} from '@shared/services/my-lists.service';
import {MySearchService} from '@shared/services/my-search-service';
import {NewsService} from '@shared/services/news.service';

// Components
import {AppComponent} from './app.component';
import {PageNotFoundComponent} from '@app/page-not-found/page-not-found.component';
import {ConfirmComponent} from '@app/auth/confirm/confirm.component';
import {VerifyComponent} from './verify/verify.component';
import {RecoveryComponent} from '@app/auth/recovery/recovery.component';

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    VerifyComponent
    ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    AuthModule,
    DashboardModule,
    HttpClientModule,
    ToasterModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [
    ToasterService,
    ApiService,
    UserService,
    CommonService,
    CompaniesService,
    InvestmentsService,
    MyListsService,
    MySearchService,
    NewsService
  ],
  bootstrap: [AppComponent],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    ConfirmComponent,
    RecoveryComponent
  ]
})

export class AppModule {
}
