import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ToasterService} from 'angular2-toaster';

// service
import {CompaniesService} from '@shared/services/companies.service';
import {InvestmentsService} from '@shared/services/investments.service';
import {MyListsService} from '@shared/services/my-lists.service';
import {UserService} from '@shared/services/user.service';
import {CommonService} from '@shared/services/common.service';

// interfaces and models
import {Investments} from '@shared/common-interfaces/investment-interface';
import {InvestmentsList} from '@shared/common-interfaces/investmentsList-interface';
import {Company} from '@shared/common-interfaces/company-interface';

/**
 * @summary SimilarCompany component
 */
@Component({
  selector: 'app-similar-company',
  templateUrl: './similar-company.component.html',
  styleUrls: ['./similar-company.component.scss']
})

export class SimilarCompanyComponent implements OnInit, OnDestroy {
  user = this.userService.getCurrentUser();
  company: Company;
  id: number;
  open = 'overview';
  investmentList: Investments[] = [];

  /**
   * @summary SimilarCompany component constructor
   * @param companiesService - companies service
   * @param investmentsService - investments service
   * @param route - ActivatedRoute service
   * @param toaster - toaster service
   * @param userService - user service
   * @param myListsService - MyLists service
   * @param commonService - Common service
   * @param router - Router service
   */
  constructor(private companiesService: CompaniesService,
              private route: ActivatedRoute,
              private investmentsService: InvestmentsService,
              private myListsService: MyListsService,
              private toaster: ToasterService,
              private userService: UserService,
              private commonService: CommonService,
              private router: Router) {
  }

  /**
   * @summary Run when SimilarCompany component init
   */
  ngOnInit() {
    this.getCompany();
  }

  /**
   * @summary Get company by id
   */
  getCompany() {
    this.route.params.subscribe(
      (params: Params) => {
        this.checkByInvestment(params['id']);
        this.id = this.getParamsId(params['id']);
        this.getInvestmentById(this.id);
        this.companiesService.getCompanyById(this.id)
          .subscribe((company: Company) => {
            this.company = company;
          }, error => {
            this.router.navigate(['/not-found']);
          });
      }
    );
  }

  /**
   * @summary Check by investments tab open
   * @param params - params service
   */
  checkByInvestment(params: any) {
    const invest = params.slice(0, 10);
    if (invest === 'investment') {
      this.open = invest;
    }
  }

  /**
   * @summary Get investments list by id company
   * @param id - id of investment
   */
  getInvestmentById(id: number) {
    this.investmentsService.getInvestmentById(id)
      .subscribe((data: InvestmentsList) => {
        this.investmentList = data.results;
      });
  }

  /**
   * @summary Add company to MyList
   */
  addToMyList() {
    this.myListsService.addToMyList(this.user.id, {entity_id: this.company.id})
      .subscribe(res => {
        this.getCompany();
        this.toaster.pop('success', res.message);
      });
  }

  /**
   * @summary Check by investments tab open
   * @param params - query params
   */
  getParamsId(params: string) {
    return this.commonService.getParamsId(params);
  }

  /**
   * @summary Open tab menu
   * @param name - name of tab
   */
  openPage(name: string) {
    this.open = name;
  }

  /**
   * @summary Delete company to MyList
   */
  deleteSimilar() {
    this.companiesService.setSimilar(null);
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.deleteSimilar();
  }
}
