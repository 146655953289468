import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {UserService} from '@shared/services/user.service';
import {User} from '@shared/common-interfaces/user-interface';

/**
 * @summary Sidebar component
 */
@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})

export class SidebarComponent implements OnInit {
  @Output() page = new EventEmitter<string>();
  user: User = this.userService.getCurrentUser();
  environment: string;
  open: string;

  /**
   * @summary Sidebar component constructor
   * @param userService - User service
   */
  constructor(private userService: UserService) {
  }

  /**
   * @summary Run when Sidebar component init
   */
  ngOnInit() {
    this.getUrl();
  }

  /**
   * @summary Open tab of menu
   * @param elem - name of tab
   */
  openTab(elem: string) {
    this.open = elem;
  }

  /**
   * @summary Get url
   */
  getUrl() {
    this.environment = this.userService.getUrl();
  }

}
