/**
 * @summary Investment service
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {NewsFilter} from '@shared/models/news-filter.model';
import {ApiService} from '@shared/services/api.service';
import {HeatMap} from '@shared/common-interfaces/heat-map-interface';
import {NameList, News, NewsList} from '@shared/common-interfaces/news-list-interface';
import {MatDialog} from '@angular/material';
import {Source} from '@shared/common-interfaces/sources-interface';
import {Types} from '@shared/common-interfaces/types-interface';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';

@Injectable()
export class NewsService {
  filtersSubject = new BehaviorSubject<NewsFilter>(new NewsFilter());

  /**
   * @summary News service constructor
   * @param apiService - Api service
   * @param dialog - data value
   */
  constructor(private apiService: ApiService,
              private dialog: MatDialog) {
  }

  /**
   * @summary Get company-news list from server
   * @param filter - filter data
   */
  getNews(filter: NewsFilter): Observable<NewsList> {
    return this.apiService.get(`api/v1/news/`, filter);
  }

  /**
   * @summary Get company-news detail by id
   * @param id - news id
   */
  getNewsById(id: number): Observable<News> {
    return this.apiService.get(`api/v1/news/${id}/`);
  }

  /**
   * @summary Get media list from server
   */
  getSources(): Observable<Source []> {
    return this.apiService.get(`api/v1/media/`);
  }

  /**
   * @summary Get types list from server
   */
  getTypes(): Observable<Types> {
    return this.apiService.get(`api/v1/types/`);
  }

  /**
   * @summary Set filter
   * @param filter - filter data
   */
  setFilter(filter: NewsFilter) {
    this.filtersSubject.next(filter);
  }

  /**
   * @summary Get heatmap data from server
   * @param filter - filter data
   */
  getHeatmap(filter: HeatmapFilter): Observable<HeatMap> {
    return this.apiService.get(`api/v1/news/heatmap/`, filter);
  }

  /**
   * @summary Get companies name list from server
   * @param name - name value
   */
  getCompanyNames(name: string): Observable<NameList> {
    return this.apiService.get(`api/v1/search/?/`, {entity_news: name});
  }

  /**
   * @summary Get csv file of news list from server
   * @param filter - filter data
   */
  getFile(filter: NewsFilter): Observable<any> {
    return this.apiService.getFile(`api/v1/news/`, filter);
  }
}
