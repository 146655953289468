import {Component, Inject, OnInit} from '@angular/core';
import {MyListsService} from '@shared/services/my-lists.service';
import {UserService} from '@shared/services/user.service';
import {Company} from '@shared/common-interfaces/company-interface';
import {ToasterService} from 'angular2-toaster';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';

/**
 * @summary RemoveMyList component
 */
@Component({
  selector: 'app-remove-my-list',
  templateUrl: './remove-my-list.component.html',
  styleUrls: ['./remove-my-list.component.scss']
})

export class RemoveMyListComponent implements OnInit {
  /**
   * @summary RemoveMyList component constructor
   * @param userService - user service
   * @param myListsService - MyLists service
   * @param toaster - toaster service
   * @param data - data for delete company
   * @param dialog - MatDialog service
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: { userId: number, companyId: number },
              private myListsService: MyListsService,
              private userService: UserService,
              private toaster: ToasterService,
              private dialog: MatDialog) {
  }

  /**
   * @summary Run when RemoveMyList component init
   */
  ngOnInit() {
  }

  /**
   * @summary Get my lists of company from server
   */
  deleteFromMyList() {
    this.myListsService.deleteFromMyList(this.data.userId, {entity_id: this.data.companyId})
      .subscribe(res => {
        this.dialog.closeAll();
        this.toaster.pop('success', res.message);
        this.myListsService.getMyList(this.data.userId)
          .subscribe((data: Company []) => {
            this.myListsService.setMyList(data);
          });
      });
  }

  /**
   * @summary Cancel delete from My List
   */
  onCancel() {
    this.dialog.closeAll();
  }
}
