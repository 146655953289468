import {Component, Inject, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {UserService} from '@shared/services/user.service';
import {ToasterService} from 'angular2-toaster';
import {MySearchService} from '@shared/services/my-search-service';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';

/**
 * @summary RemoveMyList component
 */
@Component({
  selector: 'app-remove-my-search',
  templateUrl: './remove-my-search.component.html',
  styleUrls: ['./remove-my-search.component.scss']
})

export class RemoveMySearchComponent implements OnInit {
  /**
   * @summary RemoveMyList component constructor
   * @param userService - user service
   * @param mySearchService - MySearch service
   * @param toaster - toaster service
   * @param data - data for delete company
   * @param dialog - MatDialog service
   */
  constructor(@Inject(MAT_DIALOG_DATA) public data: { idList: number [], userId: number },
              private userService: UserService,
              private toaster: ToasterService,
              private dialog: MatDialog,
              private mySearchService: MySearchService) {
  }

  /**
   * @summary Run when RemoveMyList component init
   */
  ngOnInit() {
  }

  /**
   * @summary Delete MySearch from MySearch list
   */
  deleteSearch() {
    this.mySearchService.deleteSearch(this.data.idList)
      .subscribe((res: any) => {
        this.dialog.closeAll();
        this.toaster.pop('success', 'My search delete');
        this.mySearchService.getMySearches(this.data.userId)
          .subscribe((data: MySearch []) => {
            const searches = this.mySearchService.editMySearches(data);
            this.mySearchService.setMySearches(searches);
            this.mySearchService.setSelectId([]);
          });
      });
  }

  /**
   * @summary Close dialog window
   */
  onCancel() {
    this.dialog.closeAll();
  }

}
