import {Component, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material';
import {Subscription} from 'rxjs';
import {RemoveMySearchComponent} from '@app/dashboard/my-searches/remove-my-search/remove-my-search.component';
import {CommonService} from '@shared/services/common.service';
import {MySearchService} from '@shared/services/my-search-service';
import {UserService} from '@shared/services/user.service';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';

/**
 * @summary MySearches component
 */
@Component({
  selector: 'app-my-searches',
  templateUrl: './my-searches.component.html',
  styleUrls: ['./my-searches.component.scss']
})
export class MySearchesComponent implements OnInit, OnDestroy {
  mySearchSubscription: Subscription;
  selectIdSubscription: Subscription;
  mySearches: MySearch [] = [];
  selectSearch: MySearch;
  searchName: string;
  changeName: string;
  selectId: number [] = [];
  select = {};
  open: number;
  userId = this.userService.getCurrentUser().id;

  /**
   * @summary MySearches component constructor
   * @param mySearchService - MySearch service
   * @param dialog - MatDialog service
   * @param commonService - Common service
   * @param router - Router service
   * @param userService - User service
   */
  constructor(private mySearchService: MySearchService,
              private dialog: MatDialog,
              private commonService: CommonService,
              private router: Router,
              private userService: UserService) {
  }

  /**
   * @summary Run when MyLists component init
   */
  ngOnInit() {
    this.getMySearches();
    this.getSelectId();
  }

  /**
   * @summary Save company search list
   */
  getMySearches() {
    this.mySearches = [];
    this.mySearchService.getMySearches(this.userId)
      .subscribe((mySearches: MySearch []) => {
        this.mySearches = this.mySearchService.editMySearches(mySearches);
      });
    this.mySearchSubscription = this.mySearchService.mySearchSubject
      .subscribe((data: MySearch []) => {
          this.mySearches = data;
        }
      );
  }

  /**
   * @summary Edit name of search
   */
  editSearch() {
    this.selectSearch = this.mySearches.filter(item => item.id === this.selectId[0])[0];
    this.changeName = this.selectSearch.name;
  }

  /**
   * @summary Add new name to search
   */
  addName() {
    const search = {
      name: this.changeName,
      text: JSON.stringify(this.selectSearch.text),
      user: this.userId
    };
    this.mySearchService.editSearch(search, this.selectSearch.id)
      .subscribe(() => {
        this.getMySearches();
        this.selectSearch = null;
        this.select = {};
        this.selectId = [];
      });

  }

  /**
   * @summary Open dialog window of RemoveMySearch component
   */
  openDelete() {
    this.dialog.open(RemoveMySearchComponent, {
      width: '320px',
      height: '190px',
      data: {
        idList: this.selectId,
        userId: this.userId
      }
    });
  }

  /**
   * @summary Change select list of searches
   * @param search - selected elem
   */
  changSelect(search: MySearch) {
    if (this.select[search.id]) {
      this.selectId.push(search.id);
    } else {
      const index = this.selectId.indexOf(search.id);
      this.selectId.splice(index, 1);
    }
    if (!this.selectId.length || this.selectId.length > 1) {
      this.selectSearch = null;
    }
  }

  /**
   * @summary Open description of MySearch
   * @param i - index of elem
   */
  openInfo(i: number) {
    if (i === this.open) {
      this.open = null;
      return;
    }
    this.open = i;
  }

  /**
   * @summary Get select id list
   */
  getSelectId() {
    this.selectIdSubscription = this.mySearchService.selectIdSubject
      .subscribe((selectId: number []) => {
          this.selectId = selectId;
        }
      );
  }

  /**
   * @summary Generate date creating of search
   * @param sec - number of seconds
   */
  generateDate(sec: number) {
    return new Date(sec * 1000) + '';
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.mySearchSubscription);
    this.commonService.checkSubscription(this.selectIdSubscription);
  }
}
