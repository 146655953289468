/**
 * @summary User service
 */
import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';
import {ApiService} from '@shared/services/api.service';
import {Confirm, SingIn, SingUp, User} from '@shared/common-interfaces/user-interface';
import {environment} from '../../../environments/environment';
import {HttpClient} from '@angular/common/http';
import {Radar} from '@shared/common-interfaces/radar-interface';

/**
 * @summary User service
 */
@Injectable()
export class UserService {
  currentUserSubject = new BehaviorSubject<User>(null);
  currentRadarSubject = new Subject<Radar>();
  token;

  /**
   * @summary User service constructor
   * @param apiService - Api service
   * @param router - Router service
   * @param http - HttpClient service
   */
  constructor(private apiService: ApiService,
              private router: Router,
              private http: HttpClient) {
  }

  /**
   * @summary Start sign in
   * @param model - sign up value
   */
  signUp(model: SingUp): Observable<any> {
    return this.apiService.post(`api-auth/registration/`, model);
  }

  /**
   * @summary Start sign in
   * @param cred - Credential
   */
  signIn(cred: SingIn): Observable<any> {
    return this.apiService.post(`api-auth/login/`, cred);
  }

  /**
   * @summary Set current user info
   * @param user - user data
   */
  setCurrentUser(user: User) {
    this.currentUserSubject.next(user);
  }

  /**
   * @summary Set current radar
   * @param radar - radar data
   */
  setCurrentRadar(radar: Radar) {
    this.currentRadarSubject.next(radar);
  }


  /**
   * @summary Get current user
   */
  getCurrentUser(): any {
    return this.currentUserSubject.value;
  }

  /**
   * @summary User logout
   */
  logout() {
    this.apiService.post(`api-auth/logout/`, null, null, 'text')
      .subscribe(data => {
        this.apiService.deleteAuthSettings();
        this.currentUserSubject.next(null);
        this.router.navigate(['/']);
      });
  }

  /**
   * @summary Get token
   */
  getToken() {
    return localStorage.getItem('token');
  }

  /**
   * @summary Reset password
   * @param email - email
   */
  resetPassword(email: { email: string }): Observable<any> {
    return this.apiService.post(`api-auth/password/reset/`, email);
  }

  /**
   * @summary Get current user
   */
  getUser(): Observable<any> {
    return this.http.get(`${environment.apiUrl}api/v1/users/get_user/`,
      {headers: this.apiService.setHeaders()});
  }

  /**
   * @summary Get url
   */
  getUrl(): string {
    return environment.apiUrl;
  }

  /**
   * @summary Change user password
   * @param password - email
   */
  changePassword(password: any): Observable<any> {
    return this.apiService.post(`api-auth/password/change/`, password);
  }

  /**
   * @summary Confirm new password
   * @param data - new password
   */
  confirmPassword(data: Confirm): Observable<any> {
    return this.apiService.post(`api-auth/password/reset/confirm/`, data);
  }

  /**
   * @summary Confirm registration
   * @param key - confirm key
   */
  confirmVerify(key: string) {
    return this.apiService.post(`api-auth/registration/verify-email/`, {key: key});
  }
}
