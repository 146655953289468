import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog} from '@angular/material';
import {UserService} from '@shared/services/user.service';
import {finalize} from 'rxjs/operators';
import {ToasterService} from 'angular2-toaster';
import {CommonService} from '@shared/services/common.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-confirm',
  templateUrl: './confirm.component.html',
  styleUrls: ['./confirm.component.scss']
})
export class ConfirmComponent implements OnInit {
  confirmForm: FormGroup;
  disabled: boolean;

  /**
   * @summary Recovery component constructor
   * @param dialog - MatDialog service
   * @param userService - User service
   * @param toaster - Toaster service
   * @param commonService - Common service
   * @param data - confirm data
   * @param router - Router service
   */
  constructor(private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private toaster: ToasterService,
              private commonService: CommonService,
              @Inject(MAT_DIALOG_DATA)
              public data: {
                resetId: string,
                resetKey: string
              }) {
  }

  ngOnInit() {
    this.initConfirmForm();
  }

  /**
   * @summary Init form
   */
  initConfirmForm() {
    this.confirmForm = new FormGroup({
      'uid': new FormControl(this.data.resetId, [
        Validators.required
      ]),
      'token': new FormControl(this.data.resetKey, [
        Validators.required
      ]),
      'new_password1': new FormControl(null, [
        Validators.required,
        Validators.minLength(8)
      ]),
      'new_password2': new FormControl(null, [
        Validators.required,
        this.commonService.confirmPassword('new_password1')
      ])
    });
  }

  /**
   * @summary Reset password
   */
  onConfirmPassword() {
    this.disabled = !this.disabled;
    this.userService.confirmPassword(this.confirmForm.value)
      .pipe(finalize(() => this.disabled = !this.disabled))
      .subscribe((data: any) => {
        this.close();
        this.toaster.pop('success', data.detail);
      });
  }

  /**
   * @summary Close dialog window
   */
  close() {
    this.dialog.closeAll();
  }
}
