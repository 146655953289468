import {Component, OnInit} from '@angular/core';
import {UserService} from '@shared/services/user.service';
import {Router} from '@angular/router';

/**
 * @summary Social component
 */
@Component({
  selector: 'app-social',
  templateUrl: './social.component.html',
  styleUrls: ['./social.component.scss']
})

export class SocialComponent implements OnInit {
  environment: string;
  linkedInUrl = 'accounts/linkedin_oauth2/login/?process=login';
  googleUrl = 'accounts/google/login/?process=login';

  /**
   * @summary Sign in component constructor
   * @param userService - User service
   * @param router - Router service
   */
  constructor(private userService: UserService,
              private router: Router) {

  }

  /**
   * @summary Run when Social component init
   */
  ngOnInit() {
    this.getUrl();
  }


  /**
   * @summary Get url
   */
  getUrl() {
    this.environment = this.userService.getUrl();
  }
}
