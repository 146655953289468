import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDatepickerInputEvent, MatDialog} from '@angular/material';
import {ActivatedRoute, Params} from '@angular/router';

// service
import {CompaniesService} from '@shared/services/companies.service';
import {CommonService} from '@shared/services/common.service';
import {InvestmentsService} from '@shared/services/investments.service';
import {UserService} from '@shared/services/user.service';

// interfaces and model
import {InvestmentsFilter} from '@shared/models/investments-filter.model';
import {Types} from '@shared/common-interfaces/types-interface';
import {Type} from '@shared/common-interfaces/type-interface';
import {FilterList} from '@shared/common-interfaces/filter-interface';
import {Country} from '@shared/common-interfaces/country-interface';
import {Tag} from '@shared/common-interfaces/tag-interface';
import {Tags} from '@shared/common-interfaces/tags-interface';
import {RangeSlider, RangeValue} from '@shared/common-interfaces/range-slider-interface';
import {Step} from '@shared/common-interfaces/step-interface';
import {MaxAmount} from '@shared/common-interfaces/max-amount-interface';
import {Region} from '@shared/common-interfaces/region-interface';

/**
 * @summary FilterInvestments component
 */
@Component({
  selector: 'app-filter-investments',
  templateUrl: './filter-investments.component.html',
  styleUrls: ['./filter-investments.component.scss']
})

export class FilterInvestmentsComponent implements OnInit, OnDestroy {
  stepSubscription: Subscription;
  closeSubscription: Subscription;
  filter = new InvestmentsFilter();
  tagList: Tag[];
  tagsId: number[] = [];
  tagValue: string;
  pageIndex = 0;
  radioButton = 'and';
  toggleFilter = false;
  open = false;
  openTag = false;
  openType = false;
  typeValue: string;
  types: Type[] = [];
  typesId: number[] = [];
  includeTypes: string[] = [];
  maxAmount: RangeSlider = {forUser: 0, forFilter: 0};
  stepAmount: Step = {step: 0, name: ''};
  rangeAmount: RangeValue = {minValue: 0, maxValue: 0};
  locationFilter: FilterList = {
    region: {
      id: null,
      name: null,
      include: null,
      countries: []
    },
    include: [],
    exclude: []
  };

  /**
   * @summary FilterInvestments component constructor
   * @param companiesService - Companies service
   * @param dialog - MatDialog service
   * @param commonService - Common service
   * @param investmentsService - Investments service
   * @param userService - User service
   * @param route - ActivatedRoute service
   */
  constructor(private dialog: MatDialog,
              private companiesService: CompaniesService,
              private commonService: CommonService,
              private investmentsService: InvestmentsService,
              private userService: UserService,
              private route: ActivatedRoute) {
  }

  /**
   * @summary Run when FilterInvestments component init
   */
  ngOnInit() {
    this.checkQueryParams();
    this.getTagList();
    this.getTypes();
    this.closeChange();
    this.getMaxValue();
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      this.filter = Object.assign(this.filter, params);
      if (params.page) {
        this.showFilterParams();
        this.setRangeValue(params);
      }
      this.investmentsService.setFilter(this.filter);
    });
  }

  /**
   * @summary Show filter params in filter components
   */
  showFilterParams() {
    if (typeof this.filter.country === 'string') {
      this.filter.country = [this.filter.country];
    }
    if (typeof this.filter.includeCountryName === 'string') {
      this.filter.includeCountry = [this.filter.includeCountry];
      this.filter.includeCountryName = [this.filter.includeCountryName];
    }
    if (typeof this.filter.country_exclude === 'string') {
      this.filter.country_exclude = [this.filter.country_exclude];
      this.filter.excludeCountryName = [this.filter.excludeCountryName];
    }
    if (this.filter.tag_and) {
      this.tagsId = this.filter.tag_and;
    }
    if (this.filter.tag_or) {
      this.tagsId = this.filter.tag_or;
      this.radioButton = 'or';
    }
    this.includeTypes = this.filter.includeTypes ? this.filter.includeTypes : [];
    this.addLocationValues();
  }

  /**
   * @summary Set ranges min and max value
   * @param params - query params
   */
  setRangeValue(params: Params) {
    this.filter.amount_min = +params.amount_min ? +params.amount_min : 0;
    this.filter.amount_max = +params.amount_max ? +params.amount_max : 0;
    this.rangeAmount = {
      minValue: this.filter.amount_min,
      maxValue: this.filter.amount_max
    };
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe(res => {
        this.getTagList();
      });
  }

  /**
   * @summary Get country date from filter pop up
   * @param event - filter data
   */
  getLocation(event: FilterList) {
    this.setLocationFilters(event);
    this.investmentsService.setFilter(this.filter);
    this.addLocationValues();
  }

  /**
   * @summary Set locations data for filter
   * @param filter - filter data
   */
  setLocationFilters(filter: FilterList) {
    this.includeLocation(filter.include);
    this.excludeLocation(filter.exclude);
    this.setRegionCountries(filter.region);
  }

  /**
   * @summary Set include country list for filter
   * @param location - country list
   */
  includeLocation(location: Country[]) {
    this.filter.includeCountry = [];
    this.filter.country = [];
    this.filter.includeCountryName = [];
    for (const value of location) {
      this.filter.country.push(value.country_code);
      this.filter.includeCountryName.push(value.country_name);
      this.filter.includeCountry.push(value.country_code);
    }
  }

  /**
   * @summary Set exclude country list for filter
   * @param location - country list
   */
  excludeLocation(location: Country[]) {
    this.filter.country_exclude = [];
    this.filter.excludeCountryName = [];
    for (const value of location) {
      this.filter.country_exclude.push(value.country_code);
      this.filter.excludeCountryName.push(value.country_name);
    }
  }

  /**
   * @summary Set region for filter
   */
  setRegionCountries(region: Region) {
    this.filter.regionName = region.name;
    for (const country of region.countries) {
      this.filter.country.push(country);
    }
  }

  /**
   * @summary Get date interval of founded company
   * @param event - event of date change
   * @param flag - set after or before date
   */
  getDate(event: MatDatepickerInputEvent<Date>, flag: boolean) {
    if (event.value === null) {
      return;
    }
    const time = event.value;
    const date = `${time.getFullYear()}-${time.getMonth() + 1}-${time.getDate()}`;
    if (flag) {
      this.filter.investment_date_after = date;
    }
    if (!flag) {
      this.filter.investment_date_before = date;
    }
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Get tag list
   */
  getTagList() {
    this.commonService.getTagList()
      .subscribe(
        (tags: Tags) => {
          this.tagList = tags.results;
        });
  }

  /**
   * @summary Delete region value from filter list
   */
  onDeleteRegion() {
    this.filter.regionName = null;
    this.filter.country = this.filter.includeCountry;
    this.investmentsService.setFilter(this.filter);
    this.addLocationValues();
  }

  /**
   * @summary Delete value from countriesInclude and includeCountryName array
   * @param index - index of country
   */
  deleteInclude(index: number) {
    this.filter.country.splice(index, 1);
    this.filter.includeCountryName.splice(index, 1);
    this.filter.includeCountry.splice(index, 1);
    this.investmentsService.setFilter(this.filter);
    this.addLocationValues();
  }

  /**
   * @summary Delete value from countriesExclude and excludeCountryName array
   * @param index - index of country
   */
  deleteExclude(index: number) {
    this.filter.country_exclude.splice(index, 1);
    this.filter.excludeCountryName.splice(index, 1);
    this.investmentsService.setFilter(this.filter);
    this.addLocationValues();
  }

  /**
   * @summary Delete value from includeTags and tagsId array
   * @param index - index of tag
   */
  deleteTag(index: number) {
    this.filter.includeTags.splice(index, 1);
    this.tagsId.splice(index, 1);
    this.tagFilter();
  }

  /**
   * @summary Delete value from includeTags and tagsId array
   * @param index - index of type
   */
  deleteType(index: number) {
    this.includeTypes.splice(index, 1);
    this.typesId.splice(index, 1);
    this.typeFilter();
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteAfter() {
    this.filter.investment_date_after = null;
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Delete investment date from filter
   */
  deleteBefore() {
    this.filter.investment_date_before = null;
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Get tag list
   * @param type - value of type
   */
  setType(type: Type) {
    this.includeTypes.push(type.name);
    this.typesId.push(type.id);
    this.openType = false;
    this.typeValue = null;
    this.typeFilter();
  }

  /**
   * @summary Filter by tags include 'and' or 'or' flag
   */
  typeFilter() {
    if (!this.typesId.length) {
      this.typesId = [];
    }
    this.filter.investment_type = this.typesId;
    this.filter.includeTypes = this.includeTypes;
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Change tag filter settings
   * @param event - change value event
   */
  radioChange(event: any) {
    if (!this.tagsId.length) {
      this.radioButton = event.value;
      return;
    }
    this.radioButton = event.value;
    this.tagFilter();
  }

  /**
   * @summary Get min and max value of employees
   * @param event - change value event
   */
  onAmountChange(event: any) {
    this.filter.amount_max = event.maxValue;
    this.filter.amount_min = event.minValue;
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Filter by tags include 'and' or 'or' flag
   */
  tagFilter() {
    if (!this.tagsId.length) {
      this.tagsId = [];
    }
    if (this.radioButton === 'and') {
      this.filter.tag_and = this.tagsId;
      this.filter.tag_or = null;
    }
    if (this.radioButton === 'or') {
      this.filter.tag_and = null;
      this.filter.tag_or = this.tagsId;
    }
    this.investmentsService.setFilter(this.filter);
  }

  onToggle() {
    this.toggleFilter = !this.toggleFilter;
  }

  /**
   * @summary Clear filter
   */
  skipAll() {
    this.filter.regionName = null;
    this.filter.country = [];
    this.filter.includeCountryName = [];
    this.filter.country_exclude = [];
    this.filter.excludeCountryName = [];
    this.filter.includeCountry = [];
    this.investmentsService.setFilter(this.filter);
    this.addLocationValues();
  }

  /**
   * @summary Clear tags filter
   */
  skipTags() {
    this.filter.includeTags = [];
    this.tagsId = [];
    this.tagFilter();
  }

  /**
   * @summary Clear types filter
   */
  skipTypes() {
    this.filter.investment_type = null;
    this.includeTypes = [];
    this.typesId = [];
    this.investmentsService.setFilter(this.filter);
  }

  /**
   * @summary Get type list
   */
  getTypes() {
    this.investmentsService.getTypes()
      .subscribe((data: Types) => {
        this.types = data.results;
      });
  }

  /**
   * @summary Get tag list
   * @param tag - tag value
   */
  setTag(tag: Tag) {
    this.filter.includeTags.push(tag.name);
    this.tagsId.push(tag.id);
    this.openTag = false;
    this.tagValue = null;
    this.tagFilter();
  }

  /**
   * @summary Get max value for range filters
   */
  getMaxValue() {
    this.stepSubscription = this.commonService.stepChange
      .subscribe(
        (data: MaxAmount) => {
          this.stepAmount = this.setStep(data.amount);
          this.maxAmount = this.round(data.amount);
        });
  }

  /**
   * @summary Set step of max value
   * @param amount - amount value
   */
  setStep(amount: number) {
    return this.commonService.setStep(amount);
  }

  /**
   * @summary round nax value for range filters
   * @param amount - amount value
   */
  round(amount: number) {
    return this.commonService.round(amount);
  }

  /**
   * @summary Add locations value for open location component
   */
  addLocationValues() {
    this.locationFilter = {
      region: {name: this.filter.regionName, include: true, countries: []},
      include: this.filter.includeCountry,
      exclude: this.filter.country_exclude
    };
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.stepSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }
}
