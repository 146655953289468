export class InvestmentsFilter {
  country = [];
  includeCountry = [];
  includeCountryName = [];
  country_exclude = [];
  excludeCountryName = [];
  investment_date_after = null;
  investment_date_before = null;
  amount_min = 0;
  amount_max = 0;
  page = 0;
  regionName = null;
  tag_and = [];
  tag_or = [];
  includeTags = [];
  page_size = 25;
  entity = null;
  format = null;
  investment_type = null;
  includeTypes = null;
  ordering = null;
}
