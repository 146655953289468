export class HeatmapFilter {
  filter_type = null;
  rows = null;
  columns = null;
  region = null;
  founded_in_after = null;
  founded_in_before = null;
  investment_date_after = null;
  investment_date_before = null;
  publish_date_after = null;
  publish_date_before = null;
}
