/**
 * @summary Investment service
 */
import {Injectable} from '@angular/core';
import {ApiService} from '@shared/services/api.service';
import {BehaviorSubject, Observable} from 'rxjs';

// interfaces and models
import {InvestmentsFilter} from '@shared/models/investments-filter.model';
import {HeatmapFilter} from '@shared/models/heatmap-filter.model';
import {InvestmentsList} from '@shared/common-interfaces/investmentsList-interface';
import {HeatMap} from '@shared/common-interfaces/heat-map-interface';
import {Types} from '@shared/common-interfaces/types-interface';

@Injectable()
export class InvestmentsService {
  filtersSubject = new BehaviorSubject<InvestmentsFilter>(new InvestmentsFilter());

  /**
   * @summary Investments service constructor
   * @param apiService - Api service
   */
  constructor( private apiService: ApiService) {
  }

  /**
   * @summary Get heatmap data from server
   * @param filter - filter data
   */
  getHeatmap(filter: HeatmapFilter): Observable<HeatMap> {
    return this.apiService.get(`api/v1/investments/heatmap/`, filter);
  }

  /**
   * @summary Get types list
   */
  getTypes(): Observable<Types> {
    return this.apiService.get(`api/v1/investments/types/`);
  }

  /**
   * @summary Get investment list from server
   * @param filter - filter data
   */
  getInvestments(filter: InvestmentsFilter): Observable<InvestmentsList> {
    return this.apiService.get(`api/v1/investments/`, filter);
  }

  /**
   * @summary Set filter
   * @param filter - filter data
   */
  setFilter(filter: InvestmentsFilter) {
    this.filtersSubject.next(filter);
  }

  /**
   * @summary Get investment data by id
   * @param id - id of investment
   */
  getInvestmentById(id: number): Observable<InvestmentsList> {
    return this.apiService.get(`api/v1/entities/${id}/investments/`);
  }

  /**
   * @summary Get csv file of companies list from server
   * @param filter - filter data
   */
  getFile(filter: InvestmentsFilter): Observable<any> {
    return this.apiService.getFile(`api/v1/investments/`, filter);
  }
}
