import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Injectable} from '@angular/core';
import {UserService} from '@shared/services/user.service';
import {HttpClient} from '@angular/common/http';
import {ApiService} from '@shared/services/api.service';
import {User} from '@shared/common-interfaces/user-interface';

/**
 * @summary Auth guard configuration
 */
@Injectable()
export class AuthGuard implements CanActivate {

  /**
   * @summary Auth guard configuration constructor
   * @param router - Router service
   * @param userService - User service
   * @param http - HttpClient service
   * @param apiService - Api service
   */
  constructor(private userService: UserService,
              private router: Router,
              private http: HttpClient,
              private apiService: ApiService) {
  }

  /**
   * @summary Can activate configuration
   * @param route - Activated route
   * @param state - Router state
   */
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> | boolean {
    const stateName = state.root.children[0].data.name;
    if (stateName === 'auth' && this.userService.getToken()) {
      this.router.navigate(['companies']);
      return false;
    } else if (stateName !== 'auth' && this.userService.getToken()) {
      return new Promise((resolve, reject) => {
        this.userService.getUser().subscribe(
          (user: User) => {
            this.userService.setCurrentUser(user);
            return resolve(true);
          }, err => {
            this.apiService.deleteAuthSettings();
            this.router.navigate(['']);
            return resolve(false);
          });
      });
    } else if (stateName !== 'auth' && !this.userService.getToken()) {
      this.router.navigate(['']);
      return false;
    }
    return true;
  }
}
