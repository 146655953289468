import {Component, OnInit} from '@angular/core';

/**
 * @summary Companies component
 */
@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})

export class CompaniesComponent implements OnInit {

  /**
   * @summary Companies component constructor
   */
  constructor() {
  }

  /**
   * @summary Run when Companies component init
   */
  ngOnInit() {
  }
}
