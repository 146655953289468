// Modules
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {SharedModule} from '@shared/modules/shared.module';
import {DashboardRoutingModule} from '@app/dashboard/dashboard-routing.module';
import {AgmCoreModule} from '@agm/core';

// Components
import {DashboardComponent} from '@app/dashboard/dashboard.component';
import {HeaderComponent} from '@app/dashboard/header/header.component';
import {SidebarComponent} from './sidebar/sidebar.component';
import {InvestmentsComponent} from './investments/investments.component';
import {CompaniesComponent} from '@app/dashboard/companies/companies.component';
import {CompaniesSearchComponent} from '@app/dashboard/companies/companies-search/companies-search.component';
import {CompaniesHeatmapComponent} from '@app/dashboard/companies/companies-heatmap/companies-heatmap.component';
import {InvestmentsSearchComponent} from './investments/investments-search/investments-search.component';
import {InvestmentsHeatmapComponent} from './investments/investments-heatmap/investments-heatmap.component';
import {SearchComponent} from './search/search.component';
import {MyListsComponent} from './my-lists/my-lists.component';
import {FilterInvestmentsComponent} from './investments/investments-search/filter-investments/filter-investments.component';
import {FiltersComponent} from '@app/dashboard/companies/companies-search/filters/filters.component';
import {SimilarCompanyComponent} from '@app/dashboard/companies/similar-company/similar-company.component';
import {CompanyInfoComponent} from '@app/dashboard/companies/company-info/company-info.component';
import {OverviewComponent} from '@app/dashboard/companies/company-info/overview/overview.component';
import {CompanyNewsComponent} from '@app/dashboard/companies/company-info/company-news/company-news.component';
import {SimilarComponent} from '@app/dashboard/companies/company-info/similar/similar.component';
import {CompanyInvestmentsComponent} from '@app/dashboard/companies/company-info/company-investments/company-investments.component';
import {MySearchesComponent} from './my-searches/my-searches.component';
import {RemoveMyListComponent} from './my-lists/remove-my-list/remove-my-list.component';
import {RemoveMySearchComponent} from './my-searches/remove-my-search/remove-my-search.component';
import {ChangeRadarComponent} from './search/change-radar/change-radar.component';
import {NewsComponent} from './news/news.component';
import {NewsSearchComponent} from './news/news-search/news-search.component';
import {NewsHeatmapComponent} from './news/news-heatmap/news-heatmap.component';
import {FilterNewsComponent} from './news/news-search/filter-news/filter-news.component';
import {NewsDetailComponent} from './news/news-search/news-detail/news-detail.component';
import {MyProfileComponent} from './header/my-profile/my-profile.component';
import { CompanyCommentComponent } from './companies/company-info/company-comments/company-comment.component';

@NgModule({
  declarations: [
    DashboardComponent,
    HeaderComponent,
    SidebarComponent,
    InvestmentsComponent,
    CompaniesComponent,
    CompaniesSearchComponent,
    CompaniesHeatmapComponent,
    InvestmentsSearchComponent,
    InvestmentsHeatmapComponent,
    SearchComponent,
    MySearchesComponent,
    MyListsComponent,
    FilterInvestmentsComponent,
    FiltersComponent,
    SimilarCompanyComponent,
    CompanyInfoComponent,
    OverviewComponent,
    CompanyNewsComponent,
    SimilarComponent,
    CompanyInvestmentsComponent,
    RemoveMyListComponent,
    RemoveMySearchComponent,
    ChangeRadarComponent,
    NewsComponent,
    NewsSearchComponent,
    NewsHeatmapComponent,
    FilterNewsComponent,
    NewsDetailComponent,
    MyProfileComponent,
    CompanyCommentComponent
  ],
  imports: [
    SharedModule,
    DashboardRoutingModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyAQ4y4xZt0jUge_KjE2u6tzcDtI8lLGGq8'
    })
  ],
  providers: [],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
  entryComponents: [
    CompanyInfoComponent,
    OverviewComponent,
    CompanyNewsComponent,
    SimilarComponent,
    CompanyInvestmentsComponent,
    RemoveMyListComponent,
    RemoveMySearchComponent,
    ChangeRadarComponent,
    NewsDetailComponent
  ]
})

export class DashboardModule {

}
