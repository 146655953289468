import {Component, OnInit} from '@angular/core';

/**
 * @summary Dashboard component
 */
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {
  /**
   * @summary Dashboard component constructor
   */
  constructor() { }

  /**
   * @summary Run when Dashboard component init
   */
  ngOnInit() {
  }
}
