// Modules
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {Ng5SliderModule} from 'ng5-slider';
import {DataTablesModule} from 'angular-datatables';
import {MaterialModule} from '@shared/modules/app-material.module';

// Directives
import {PieChartDirective} from '@shared/directives/pie-chart.directive';
import {ClickOutsideDirective} from '@shared/directives/clickOutside.directive';

// Pipes
import {ShortenWordPipe} from '@shared/pipes/shorten-word.pipe';
import {SortNamePipe} from '@shared/pipes/sort-name.pipe';
import {SearchPipe} from '@shared/pipes/search.pipe';
import {NumberFormatPipe} from '@shared/pipes/number-format.pipe';

// Components
import {RangeSliderComponent} from '@shared/components/range-slider/range-slider.component';
import {LocationFilterComponent} from '@shared/components/location-filter/location-filter.component';
import {HeatMapFilterComponent} from '@shared/components/heat-map-filter/heat-map-filter.component';
import {ProgressSpinnerComponent} from '@shared/components/progress-spinner/progress-spinner.component';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MaterialModule,
    HttpClientModule,
    Ng5SliderModule,
    DataTablesModule
  ],
  declarations: [
    ShortenWordPipe,
    SortNamePipe,
    SearchPipe,
    NumberFormatPipe,
    PieChartDirective,
    RangeSliderComponent,
    LocationFilterComponent,
    HeatMapFilterComponent,
    ClickOutsideDirective,
    ProgressSpinnerComponent
  ],
  exports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    MaterialModule,
    ShortenWordPipe,
    SortNamePipe,
    SearchPipe,
    NumberFormatPipe,
    PieChartDirective,
    RangeSliderComponent,
    DataTablesModule,
    LocationFilterComponent,
    HeatMapFilterComponent,
    ClickOutsideDirective,
    ProgressSpinnerComponent
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class SharedModule {
}
