import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {SearchList} from '@shared/common-interfaces/search-list-interface';
import {MatDialog} from '@angular/material';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {SearchData} from '@shared/models/search-data.model';

// components
import {ChangeRadarComponent} from '@app/dashboard/search/change-radar/change-radar.component';
import {NewsDetailComponent} from '@app/dashboard/news/news-search/news-detail/news-detail.component';

// service
import {UserService} from '@shared/services/user.service';
import {CommonService} from '@shared/services/common.service';
import {CompaniesService} from '@shared/services/companies.service';

// interfaces and models
import {User} from '@shared/common-interfaces/user-interface';
import {News} from '@shared/common-interfaces/news-list-interface';
import {Radar} from '@shared/common-interfaces/radar-interface';
import {Company} from '@shared/common-interfaces/company-interface';

/**
 * @summary Search component
 */
@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit, OnDestroy {
  userSubscription: Subscription;
  searchSubscription: Subscription;
  closeSubscription: Subscription;
  searchList: SearchList;
  radar = this.userService.getCurrentUser().radar;
  searchData: SearchData = new SearchData();
  logo = '/assets/imgs/logo.svg';
  page: string;
  id: number;
  invest: boolean;

  /**
   * @summary Search component constructor
   * @param dialog - MatDialog service
   * @param userService - user service
   * @param companiesService - Companies service
   * @param commonService - Common service
   * @param route - ActivatedRoute service
   * @param router - data value
   */
  constructor(private commonService: CommonService,
              private companiesService: CompaniesService,
              private dialog: MatDialog,
              private router: Router,
              private userService: UserService,
              private route: ActivatedRoute) {
  }

  /**
   * @summary Run when Search component init
   */
  ngOnInit() {
    this.userChange();
    this.getSearchList();
    this.checkQueryParams();
    this.closeChange();
  }

  /**
   * @summary Init get companies when radar changing
   */
  userChange() {
    this.userSubscription = this.userService.currentUserSubject
      .subscribe((user: User) => {
          this.radar = user.radar;
        }
      );
  }

  /**
   * @summary Check query params
   */
  checkQueryParams() {
    this.route.queryParams.subscribe((params: Params) => {
      if (params.page) {
        this.page = params.page;
        this.id = params.id;
        this.invest = params.invest;
      }
    });
  }

  /**
   * @summary Get max value for range filters
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe((res: boolean) => {
        if (this.page) {
          this.router.navigate([this.page], {queryParams: {id: this.id, invest: this.invest}});
        }
        this.page = null;
      });
  }

  /**
   * @summary Get company list from server
   */
  getSearchList() {
    this.searchSubscription = this.companiesService.searchSubject
      .subscribe((data: SearchList) => {
          this.searchList = data;
        }
      );
  }

  /**
   * @summary Open more company
   */
  moreCompanies() {
    this.searchData.full_entities = true;
    this.searchData.q = this.searchList.q;
    this.companiesService.getSearchCompanies(this.searchData)
      .subscribe((data) => {
        this.searchList.entities = data.entities;
      });

  }

  /**
   * @summary Open more company
   */
  moreInvestments() {
    this.searchData.full_investments = true;
    this.searchData.q = this.searchList.q;
    this.companiesService.getSearchCompanies(this.searchData)
      .subscribe((data) => {
        this.searchList.investments = data.investments;
      });
  }

  /**
   * @summary Open all company-news list
   */
  moreNews() {
    this.searchData.full_news = true;
    this.searchData.q = this.searchList.q;
    this.companiesService.getSearchCompanies(this.searchData)
      .subscribe((data) => {
        this.searchList.news = data.news;
      });
  }

  /**
   * @summary Check radar of company
   * @param company - Company data
   */
  checkCompanyRadar(company: Company) {
    const page = '/companies';
    if (this.checkRadar(company.radar)) {
      this.router.navigate([page, company.id]);
      return;
    }
    this.changeRadar(company.radar, company.id, page, false);
  }

  /**
   * @summary Check radar of investment
   * @param company - Company data
   */
  checkInvestmentRadar(company: Company) {
    if (this.checkRadar(company.radar)) {
      this.router.navigate(['/companies', 'investment' + company.id]);
      return;
    }
    this.changeRadar(company.radar, company.id, 'companies', true);
  }

  /**
   * @summary Check radar of company
   * @param news - News data
   */
  checkNewsRadar(news: News) {
    if (this.checkRadar(news.radar)) {
      this.router.navigate(['/news']);
      this.openDetail(news);
      return;
    }
    this.changeRadar(news.radar, news.id, 'news', false);
  }

  /**
   * @summary Open dialog window of Recovery component
   * @param news - News data
   */
  openDetail(news: News) {
    this.dialog.open(NewsDetailComponent, {
      width: '920px',
      data: {
        newsList: [],
        news: news,
        i: null
      }
    });
  }

  /**
   * @summary Check radars of elem
   * @param radars - radars list
   */
  checkRadar(radars: Radar []) {
    for (const radar of radars) {
      if (+radar.id === +this.radar) {
        return true;
      }
    }
    return false;
  }

  /**
   * @summary Change radar for open information
   * @param radars - radars list
   * @param id - id of elem
   * @param page - name of page
   * @param invest - investment tab
   */
  changeRadar(radars: Radar [], id: number, page: string, invest: boolean) {
    this.dialog.open(ChangeRadarComponent, {
      width: '425px',
      data: {
        radars: radars,
        page: page,
        id: id,
        invest: invest,
      }
    });
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.searchSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
    this.commonService.checkSubscription(this.userSubscription);
  }
}
