/**
 * @summary Api service
 */
import {Injectable} from '@angular/core';
import {HttpHeaders, HttpClient, HttpParams} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {environment} from 'environments/environment';
import {Toast, ToasterService} from 'angular2-toaster';
import {catchError} from 'rxjs/internal/operators';

@Injectable()
export class ApiService {
  url = environment.apiUrl;

  /**
   * @summary ApiService constructor
   * @param http - HttpClient service
   * @param toaster - Toaster service
   */
  constructor(private http: HttpClient,
              private toaster: ToasterService) {
  }

  /**
   * @summary Set headers
   */
  setHeaders(saveFile?: boolean): HttpHeaders {
    const headersConfig = {
      'Content-Type': saveFile ? 'text/csv' : 'application/json',
      'Accept': saveFile ? 'text/csv' : 'application/json',
      'Authorization': localStorage.getItem('token') ? 'Token ' + localStorage.getItem('token') : ''
    };
    return new HttpHeaders(headersConfig);
  }

  /**
   * @summary Edit format error
   */
  formatErrors(err: any) {
    const toast: Toast = {
      type: 'error',
      timeout: 10000
    };
    if (err && typeof err.error === 'string') {
      err.error = JSON.parse(err.error);
    }
    toast.title = err.error[Object.keys(err.error)[0]][0];
    this.toaster.pop(toast);
    return throwError(err);
  }

  /**
   * @summary Convert query params
   */
  convertQueryParams(params: any) {
    if (params) {
      let query = new HttpParams();
      Object.keys(params).forEach((key) => {
        if (params[key] && !Array.isArray(params[key])) {
          query = query.append(key, params[key]);
        }
        if (params[key] && Array.isArray(params[key])) {
          for (const elem of params[key]) {
            query = query.append(key, elem);
          }
        }
      });
      return query;
    } else {
      return params;
    }
  }

  get(path: string, params?: any, responseType: any = 'json'): Observable<any> {
    params = this.convertQueryParams(params);
    return this.http.get(`${this.url}${path}`, {headers: this.setHeaders(), params, responseType: responseType})
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  put(path: string, body: Object = {}, params?: any, responseType: any = 'json'): Observable<any> {
    params = this.convertQueryParams(params);
    return this.http.put(`${this.url}${path}`,
      JSON.stringify(body), {headers: this.setHeaders(), responseType: responseType, params})
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  post(path: string, body: Object = {}, params?: any, responseType: any = 'json'): Observable<any> {
    params = this.convertQueryParams(params);
    console.log("body____: ", body)
    return this.http.post(`${this.url}${path}`,
      body, {headers: this.setHeaders(), responseType: responseType, params})
      .pipe(catchError((error) => this.formatErrors(error)));
  }


  delete(path, params?: any, body?: Object): Observable<any> {
    const options = {
      headers: this.setHeaders(),
      body: body,
      params: this.convertQueryParams(params)
    };
    return this.http.delete(`${this.url}${path}`, options)
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  patch(path: string, body: Object = {}, params?: any, responseType: any = 'json'): Observable<any> {
    params = this.convertQueryParams(params);
    return this.http.patch(`${this.url}${path}`,
      JSON.stringify(body), {headers: this.setHeaders(), responseType: responseType, params})
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  getFile(path: string, params?: any, responseType: any = 'blob'): Observable<any> {
    params = this.convertQueryParams(params);
    return this.http
      .get(`${this.url}${path}`, {headers: this.setHeaders(true), params, responseType: responseType, observe: 'response'})
      .pipe(catchError((error) => this.formatErrors(error)));
  }

  /**
   * @summary Delete token from local storage
   */
  deleteAuthSettings() {
    localStorage.removeItem('token');
  }
}
