// Modules
import {NgModule} from '@angular/core';
import {AuthRoutingModule} from './auth-routing.module';
import {SharedModule} from '@shared/modules/shared.module';

// Components
import {SignInComponent} from './sign-in/sign-in.component';
import {SingUpComponent} from './sing-up/sing-up.component';
import {AuthComponent} from '@app/auth/auth.component';
import { RecoveryComponent } from './recovery/recovery.component';
import { SocialComponent } from './social/social.component';
import { ConfirmComponent } from './confirm/confirm.component';

@NgModule({
  declarations: [
    AuthComponent,
    SignInComponent,
    SingUpComponent,
    RecoveryComponent,
    SocialComponent,
    ConfirmComponent
  ],
  entryComponents: [RecoveryComponent],
  imports: [
    AuthRoutingModule,
    SharedModule,
  ],
  providers: [
  ]
})

export class AuthModule {
}
