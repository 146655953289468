import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {MatDialog} from '@angular/material';
import {ToasterService} from 'angular2-toaster';
import * as FileSaver from 'file-saver';

// service
import {InvestmentsService} from '@shared/services/investments.service';
import {CompaniesService} from '@shared/services/companies.service';
import {CommonService} from '@shared/services/common.service';
import {UserService} from '@shared/services/user.service';
import {MySearchService} from '@shared/services/my-search-service';

// interfaces and models
import {InvestmentsFilter} from '@shared/models/investments-filter.model';
import {InvestmentsList} from '@shared/common-interfaces/investmentsList-interface';
import {Investments} from '@shared/common-interfaces/investment-interface';
import {Company} from '@shared/common-interfaces/company-interface';
import {MySearch} from '@shared/common-interfaces/my-searches-interface';
import {Th} from '@shared/common-interfaces/th-list-interface';

/**
 * @summary InvestmentsSearch component
 */
@Component({
  selector: 'app-investments-search',
  templateUrl: './investments-search.component.html',
  styleUrls: ['./investments-search.component.scss']
})

export class InvestmentsSearchComponent implements OnInit, OnDestroy {
  filterSubscription: Subscription;
  closeSubscription: Subscription;
  filters: InvestmentsFilter;
  companies: Company [] = [];
  investments: Investments [] = [];
  thList: Th [] = [
    {name: 'name', sort: 'name', flag: false},
    {name: 'country', sort: 'country', flag: false},
    {name: 'city', sort: 'city', flag: false},
    {name: 'total raised, $', sort: 'total_raised', flag: false},
    {name: 'amount', sort: 'amount', flag: false},
    {name: 'currency', sort: 'currency', flag: false},
    {name: 'investment date', sort: 'investment_date', flag: false}
  ];
  pageSize: number;
  pageSizes = [10, 25, 50];
  lengthList: number;
  pageIndex = 0;
  region: string;
  openPageSize = false;
  search: MySearch;
  active: number;
  user = this.userService.getCurrentUser();

  /**
   * @summary CompaniesSearch component constructor
   * @param dialog - MatDialog service
   * @param userService - User service
   * @param companiesService - Companies service
   * @param commonService - Common service
   * @param toaster - Toaster service
   * @param mySearchService - MySearch service
   * @param investmentsService - Investments service
   */
  constructor(private dialog: MatDialog,
              private companiesService: CompaniesService,
              private investmentsService: InvestmentsService,
              private commonService: CommonService,
              private userService: UserService,
              private toaster: ToasterService,
              private mySearchService: MySearchService) {
  }

  /**
   * @summary Run when CompaniesSearch component init
   */
  ngOnInit() {
    this.setFilter();
    this.closeChange();
  }

  /**
   * @summary Set filters to get investments list
   */
  setFilter() {
    this.filterSubscription = this.investmentsService.filtersSubject
      .subscribe((filter: InvestmentsFilter) => {
          this.filters = filter;
          this.checkPage();
          this.getInvestments();
        }
      );
  }

  /**
   * @summary Listen radar changing
   */
  closeChange() {
    this.closeSubscription = this.commonService.closeChange
      .subscribe((res: boolean) => {
        this.getInvestments();
      });
  }

  /**
   * @summary Get company list from server
   */
  getInvestments() {
    if (!this.user.allowed_radars.length && !this.user.is_admin) {
      return;
    }
    this.investmentsService.getInvestments(this.filters)
      .subscribe((data: InvestmentsList) => {
          this.filters.page = this.pageIndex + 1;
          this.investments = data.results;
          this.lengthList = data.count;
          this.pageSize = data.results ? data.results.length : 25;
          this.commonService.setAmount(0, 0, data.max_amount);
        }
      );
  }

  /**
   * @summary Check number of page on zero
   */
  checkPage() {
    if (this.filters.page > 0) {
      this.filters.page = 0;
    }
    this.pageIndex = 0;
  }

  /**
   * @summary Change page of company list
   * @param event - event of pagination
   */
  changePage(event: any) {
    this.filters.page = event.pageIndex + 1;
    this.pageIndex = event.pageIndex;
    this.getInvestments();
  }

  /**
   * @summary Set number of company on page
   * @param value - page size
   */
  setPageSize(value: number) {
    this.filters.page_size = value;
    this.checkPage();
    this.getInvestments();
    this.openPageSize = false;
  }

  /**
   * @summary Set value name for search filter
   */
  searchName() {
    this.checkPage();
    this.getInvestments();
  }

  /**
   * @summary Save investments list in csv file
   */
  saveCsv() {
    this.filters.format = 'csv';
    this.investmentsService.getFile(this.filters)
      .subscribe((res: any) => {
        FileSaver.saveAs(res.body, this.commonService.getFileName(res));
      });
  }

  /**
   * @summary Save company search list
   */
  onSave() {
    this.search = {
      name: this.commonService.createDate('invest_search'),
      text: JSON.stringify(this.filters),
      user: this.user.id
    };
    this.mySearchService.saveMySearch(this.search)
      .subscribe(res => {
        this.toaster.pop('success', 'Search list added');
      });
  }

  /**
   * @summary Sort table column by min and max
   * @param th - page size
   * @param i - index
   */
  sort(th: Th, i: number) {
    this.active = i;
    for (const elem of this.thList) {
      if (elem.name !== th.name) {
        elem.flag = false;
      }
    }
    let sort = '';
    th.flag = !th.flag;
    if (!th.flag) {
      sort = '-';
    }
    if (th.sort === 'name' || th.sort === 'country' || th.sort === 'city' || th.sort === 'total_raised') {
      sort = sort + 'entity__' + sort.slice(0, -1);
    }
    this.filters.ordering = sort + th.sort;
    this.getInvestments();
  }

  /**
   * @summary Cleanup logic
   */
  ngOnDestroy() {
    this.commonService.checkSubscription(this.filterSubscription);
    this.commonService.checkSubscription(this.closeSubscription);
  }
}
